import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import Logout from "../auth/Logout";
import { useDispatch, useSelector } from 'react-redux';
import {fetchCategories} from '../../redux/slices/categoriesSlice';
import {setCategory, fetchProductsByCategory } from './../../redux/slices/filterProductSlice';
import { useNavigate } from 'react-router-dom';
import { fetchProducts } from '../../redux/slices/productSlice';
import { ProductInfo } from '../../ecommerce/product/ProductInfo';
import { LiveSearch } from '../../ecommerce/LiveSearch';

export const Header = () => {
  const { user } = useSelector((state) => state.auth);
  const cartItems = useSelector((state) => state.cart.items);

  const { categories, loading, error } = useSelector((state) => state.categories);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { products} = useSelector(state => state.products)
  const [showModel, setShowModel] = useState(false)

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleCategoryClick = (category) => {
    navigate(`/products/${category}`);
  };

  const renderSubCategories = (parentCategory) => {
    const subCategories = categories.filter(cat => cat.parent_category_id === parentCategory.id);
    return (
      <div className="">
        {subCategories.map((subCategory) => (
          <div key={subCategory.id} style={{style: {cursor: 'pointer'}}}>
            <a onClick={() => handleCategoryClick(subCategory.category_name)} className="badge text-secondary p-2 border rounded">
              {subCategory.category_name[0].toUpperCase() + subCategory.category_name.slice(1)}
            </a>
          </div>
        ))}
      </div>
    );
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }
  //   console.log(user);
  return (
    <>
      <header className="header-area header-style-1 header-height-2">
        <div className="mobile-promotion">
            <span>Grand opening, <strong>up to 15%</strong> off all items. Only <strong>3 days</strong> left</span>
        </div>
        <div className="header-top header-top-ptb-1 d-none d-lg-block">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-3 col-lg-4">
                        <div className="header-info">
                            <ul>
                                <li><Link to='/about-us'>About Us</Link></li>
                                <li><Link to='/contact-us'>Contact Us</Link></li>
                                <li><Link to='/track-your-product'>Order Tracking</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-4">
                        <div className="text-center">
                            <div id="news-flash" className="d-inline-block">
                                <ul>
                                    <li>100% Secure delivery without contacting the courier</li>
                                    <li>Supper Value Deals - Save more with coupons</li>
                                    <li>Trendy 25silver jewelry, save up 35% off today</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4">
                        <div className="header-info header-info-right">
                            <ul>
                                <li>Need help? Call Us: <strong className="text-brand"> +1 347-476-1404</strong></li>
                                {/* <li>
                                    <a className="language-dropdown-active" href="#">English <i className="fi-rs-angle-small-down"></i></a>
                                    <ul className="language-dropdown">
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/flag-fr.png`} alt="" />Français</a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/flag-dt.png`} alt="" />Deutsch</a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/flag-ru.png`} alt="" />Pусский</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="language-dropdown-active" href="#">USD <i className="fi-rs-angle-small-down"></i></a>
                                    <ul className="language-dropdown">
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/flag-fr.png`} alt="" />INR</a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/flag-dt.png`} alt="" />MBP</a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/flag-ru.png`} alt="" />EU</a>
                                        </li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
            <div className="container">
                <div className="header-wrap">
                    <div className="logo logo-width-1">
                        {<Link to='/'><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/ncart.png`} alt="logo" /></Link>}
                    </div>
                    <div className="header-right">
                        <div className="search-style-2">
                        <div className="search-container">
                            <LiveSearch />
                        </div>
                            {/* <form action="#">
                                <input type="text" placeholder="Search for items..." />
                            </form> */}
                        </div>
                        <div className="header-action-right">
                            <div className="header-action-2">
                                {/* <div className="search-location">
                                    <form action="#">
                                        <select className="select-active">
                                            <option>Your Location</option>
                                            <option>Alabama</option>
                                            <option>Alaska</option>
                                            <option>Arizona</option>
                                            <option>Delaware</option>
                                            <option>Florida</option>
                                            <option>Georgia</option>
                                            <option>Hawaii</option>
                                            <option>Indiana</option>
                                            <option>Maryland</option>
                                            <option>Nevada</option>
                                            <option>New Jersey</option>
                                            <option>New Mexico</option>
                                            <option>New York</option>
                                        </select>
                                    </form>
                                </div> */}
                                {/* <div className="header-action-icon-2">
                                    <a href="shop-compare.html">
                                        <img className="svgInject" alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-compare.svg`} />
                                        <span className="pro-count blue">3</span>
                                    </a>
                                    <a href="shop-compare.html"><span className="lable ml-0">Compare</span></a>
                                </div> */}
                                {/* <div className="header-action-icon-2">
                                    <Link to='/wishlist'>
                                        <img className="svgInject" alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-heart.svg`} />
                                        <span className="pro-count blue">{cartItems.length}</span>
                                    </Link>
                                    {<Link to='/wishlist'><span className="lable">Wishlist</span></Link>}
                                </div> */}
                                <div className="header-action-icon-2">
                                    <Link to='/cart'>
                                        <img alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-cart.svg`} />
                                        <span className="pro-count blue">{cartItems.length}</span>
                                    </Link>
                                    <Link to='cart'><span className="lable">Cart</span></Link>
                                    {/* <div className="cart-dropdown-wrap cart-dropdown-hm2">
                                        <ul>
                                            <li>
                                                <div className="shopping-cart-img">
                                                    <a href="shop-product-right.html"><img alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/shop/thumbnail-3.jpg`} /></a>
                                                </div>
                                                <div className="shopping-cart-title">
                                                    <h4><a href="shop-product-right.html">Daisy Casual Bag</a></h4>
                                                    <h4><span>1 × </span>$800.00</h4>
                                                </div>
                                                <div className="shopping-cart-delete">
                                                    <a href="#"><i className="fi-rs-cross-small"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="shopping-cart-img">
                                                    <a href="shop-product-right.html"><img alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/shop/thumbnail-2.jpg`} /></a>
                                                </div>
                                                <div className="shopping-cart-title">
                                                    <h4><a href="shop-product-right.html">Corduroy Shirts</a></h4>
                                                    <h4><span>1 × </span>$3200.00</h4>
                                                </div>
                                                <div className="shopping-cart-delete">
                                                    <a href="#"><i className="fi-rs-cross-small"></i></a>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="shopping-cart-footer">
                                            <div className="shopping-cart-total">
                                                <h4>Total <span>$4000.00</span></h4>
                                            </div>
                                            <div className="shopping-cart-button">
                                                <a href="shop-cart.html" className="outline">View cart</a>
                                                <a href="shop-checkout.html">Checkout</a>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                
                                {!user ? (
                                    <div className="header-action-icon-2">
                                        <Link to="/login">
                                            <span className="lable ml-0">Login |</span>
                                        </Link>
                                        <Link to='/registration'>
                                            <span className="lable ml-0">Register</span>
                                        </Link>
                                        
                                    </div>
                                ) :(<div className="header-action-icon-2">
                                    <Link to='/dashboard'>
                                        <img className="svgInject" alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-user.svg`} />
                                    </Link>
                                    <Link to='/dashboard'>
                                        <span className="lable ml-0">Account</span>
                                    </Link>
                                    <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                        <ul>
                                            <li>
                                                <Link to='/dashboard'>
                                                    <i className="fi fi-rs-user mr-10"></i>My account
                                                </Link>
                                            </li>
                                            <li>
                                                <a href="page-account.html"><i className="fi fi-rs-location-alt mr-10"></i>Order Tracking</a>
                                            </li>
                                            <li>
                                                <a href="page-account.html"><i className="fi fi-rs-label mr-10"></i>My Voucher</a>
                                            </li>
                                            <li>
                                                <a href="shop-wishlist.html"><i className="fi fi-rs-heart mr-10"></i>My Wishlist</a>
                                            </li>
                                            <li>
                                                <a href="page-account.html"><i className="fi fi-rs-settings-sliders mr-10"></i>Setting</a>
                                            </li>
                                            <li>
                                                <Logout />
                                            </li>
                                        </ul>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header-bottom header-bottom-bg-color sticky-bar">
            <div className="container">
                <div className="header-wrap header-space-between position-relative">
                    <div className="logo logo-width-1 d-block d-lg-none">
                        <a href="index.html"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/ncart.png`} alt="logo" /></a>
                    </div>
                    <div className="header-nav d-none d-lg-flex">
                        {/* <div className="main-categori-wrap d-none d-lg-block">
                            <a className="categories-button-active" href="#">
                                <span className="fi-rs-apps"></span> <span className="et">Browse</span> All Categories
                                <i className="fi-rs-angle-down"></i>
                            </a>
                            <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading">
                                <div className="d-flex categori-dropdown-inner">
                                    <ul>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-1.svg`} alt="" />Milks and Dairies</a>
                                        </li>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-2.svg`} alt="" />Clothing & beauty</a>
                                        </li>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-3.svg`} alt="" />Pet Foods & Toy</a>
                                        </li>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-4.svg`} alt="" />Baking material</a>
                                        </li>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-5.svg`} alt="" />Fresh Fruit</a>
                                        </li>
                                    </ul>
                                    <ul className="end">
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-6.svg`} alt="" />Wines & Drinks</a>
                                        </li>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-7.svg`} alt="" />Fresh Seafood</a>
                                        </li>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-8.svg`} alt="" />Fast food</a>
                                        </li>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-9.svg`} alt="" />Vegetables</a>
                                        </li>
                                        <li>
                                            <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/category-10.svg`} alt="" />Bread and Juice</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="more_slide_open" style={{display: "none"">
                                    <div className="d-flex categori-dropdown-inner">
                                        <ul>
                                            <li>
                                                <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-1.svg`} alt="" />Milks and Dairies</a>
                                            </li>
                                            <li>
                                                <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-2.svg`} alt="" />Clothing & beauty</a>
                                            </li>
                                        </ul>
                                        <ul className="end">
                                            <li>
                                                <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-3.svg`} alt="" />Wines & Drinks</a>
                                            </li>
                                            <li>
                                                <a href="shop-grid-right.html"> <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-4.svg`} alt="" />Fresh Seafood</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="more_categories"><span className="icon"></span> <span className="heading-sm-1">Show more...</span></div>
                            </div>
                        </div> */}
                         <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
      <nav>
        <ul className="d-flex align-items-center">
          <li
            className="position-static"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <a className="btn btn-success text-light" href="#">
              <span className="fi-rs-apps"></span> <span className="et">Browse</span> All Categories
              <i className="fi-rs-angle-down"></i>
            </a>
            {isDropdownVisible && (
              <div className="mega-menu dropdown-menu">
                <div className="container">
                  <div className="row">
                    {categories.filter(cat => !cat.parent_category_id).map((category) => (
                      <div className="col-md-2" key={category.id}>
                        <h5 className="category-link">{category.category_name[0].toUpperCase() +
        category.category_name.slice(1)}</h5>
                        {renderSubCategories(category)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </li>
          <li className="hot-deals"><Link to='/shop'>Deal</Link></li>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/shop'>Shop</Link></li>
          <li><Link to='/vendors'>Vendors</Link></li>
          <li><Link to='/about-us'>About</Link></li>
          <li><Link to='/contact-us'>Contact</Link></li>
          <li><Link to='/vendor-registration'>Become a Vendor</Link></li>
        </ul>
      </nav>
    </div>
                    </div>
                    <div className="hotline d-none d-lg-flex">
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-headphone.svg`} alt="hotline" />
                        <p>1900 - 888<span>24/7 Support Center</span></p>
                    </div>
                    <div className="header-action-icon-2 d-block d-lg-none">
                        <div className="burger-icon burger-icon-white">
                            <span className="burger-icon-top"></span>
                            <span className="burger-icon-mid"></span>
                            <span className="burger-icon-bottom"></span>
                        </div>
                    </div>
                    <div className="header-action-right d-block d-lg-none">
                        <div className="header-action-2">
                            <div className="header-action-icon-2">
                                <a href="shop-wishlist.html">
                                    <img alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-heart.svg`} />
                                    <span className="pro-count white">4</span>
                                </a>
                            </div>
                            <div className="header-action-icon-2">
                                <a className="mini-cart-icon" href="#">
                                    <img alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-cart.svg`} />
                                    <span className="pro-count white">2</span>
                                </a>
                                <div className="cart-dropdown-wrap cart-dropdown-hm2">
                                    <ul>
                                        <li>
                                            <div className="shopping-cart-img">
                                                <a href="shop-product-right.html"><img alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/shop/thumbnail-3.jpg`} /></a>
                                            </div>
                                            <div className="shopping-cart-title">
                                                <h4><a href="shop-product-right.html">Plain Striola Shirts</a></h4>
                                                <h3><span>1 × </span>$800.00</h3>
                                            </div>
                                            <div className="shopping-cart-delete">
                                                <a href="#"><i className="fi-rs-cross-small"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="shopping-cart-img">
                                                <a href="shop-product-right.html"><img alt="Nest" src={`${process.env.PUBLIC_URL}/assets/imgs/shop/thumbnail-4.jpg`} /></a>
                                            </div>
                                            <div className="shopping-cart-title">
                                                <h4><a href="shop-product-right.html">Macbook Pro 2022</a></h4>
                                                <h3><span>1 × </span>$3500.00</h3>
                                            </div>
                                            <div className="shopping-cart-delete">
                                                <a href="#"><i className="fi-rs-cross-small"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="shopping-cart-footer">
                                        <div className="shopping-cart-total">
                                            <h4>Total <span>$383.00</span></h4>
                                        </div>
                                        <div className="shopping-cart-button">
                                            <a href="shop-cart.html">View cart</a>
                                            <a href="shop-checkout.html">Checkout</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </header>
    </>
  )
}