import { useState } from 'react';
import Sidebar from "../ecommerce/home/Sidebar";
import Test from "../Test";
import {Banners} from  '../ecommerce/home/Banners'
import { FeaturedCategories } from "../ecommerce/home/FeaturedCategories";
import { PopularProducts } from "../ecommerce/home/PopularProducts";
import { Banner2 } from "../ecommerce/home/Banner2";
import { DailyBestSells } from "../ecommerce/home/DailyBestSells";
import { DealsOfTheDay } from "../ecommerce/home/DealsOfTheDay";
import { TopSelling } from "../ecommerce/home/TopSelling";
import ModelView from "../ecommerce/ModelView";



export const Home = () => {
  
  const [showModelView, setShowModelView] = useState(false);
  return (
    <>
      <Sidebar />
      {/* <FeaturedCategories /> */}
      <Banners />
      <PopularProducts productShowcaseName={"Trending Now"} />
      <PopularProducts productShowcaseName={"New Arrivals"} />
      <PopularProducts productShowcaseName={"Best Sellings"} />
      <PopularProducts productShowcaseName={"Exclusive Deals"} />
      <PopularProducts productShowcaseName={"Top Rated"} />
      <PopularProducts productShowcaseName={"Featured Brands"} />
      <Banner2 /> 
      <DailyBestSells />
     
      {showModelView && <ModelView data={showModelView} setData={setShowModelView} />}

      {/* <ModelView data={true} /> */}

      {/* <DealsOfTheDay /> */}
    </>
  )
}