import React, { useState } from 'react';

const UserInfo = ({ user, setUser, nextStep }) => {
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'fname':
                if (!value) error = 'First name is required';
                break;
            case 'lname':
                if (!value) error = 'Last name is required';
                break;
            case 'email':
                if (!value) error = 'Email is required';
                else if (!/\S+@\S+\.\S+/.test(value)) error = 'Email is invalid';
                break;
            case 'password':
                if (!value) error = 'Password is required';
                else if (value.length < 6) error = 'Password must be at least 6 characters';
                break;
            case 'phone_no':
                if (!value) error = 'User Phone number is required';
                else if (!/^\d+$/.test(value)) error = 'Phone number is invalid';
                break;
            case 'website':
                if (value && !/^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/.test(value)) 
                    error = 'Website URL is invalid';
                break;
            case 'gender':
                if (!value) error = 'Gender is required';
                break;
            case 'date_of_birth':
                if (!value) error = 'Date of Birth is required';
                break;
            default:
                break;
        }
        setErrors({ ...errors, [name]: error });
    };

    const isValid = () => {
        return !Object.values(errors).some(error => error !== '') && 
               Object.keys(user).length >= 8; // Ensure all fields are filled
    };

    return (
        <div className='row justify-content-center m-5'>
            <div className='col-md-6 card shadow p-4 mb-5 bg-body rounded'>
                <h2>Personal Information</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                name="fname"
                                className={`form-control ${errors.fname ? 'is-invalid' : ''}`}
                                value={user.fname || ''}
                                onChange={handleChange}
                            />
                            {errors.fname && <div className="invalid-feedback">{errors.fname}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Last Name</label>
                            <input
                                type="text"
                                name="lname"
                                className={`form-control ${errors.lname ? 'is-invalid' : ''}`}
                                value={user.lname || ''}
                                onChange={handleChange}
                            />
                            {errors.lname && <div className="invalid-feedback">{errors.lname}</div>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                value={user.email || ''}
                                onChange={handleChange}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                name="password"
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                value={user.password || ''}
                                onChange={handleChange}
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Phone Number</label>
                            <input
                                type="text"
                                name="phone_no"
                                className={`form-control ${errors.phone_no ? 'is-invalid' : ''}`}
                                value={user.phone_no || ''}
                                onChange={handleChange}
                            />
                            {errors.phone_no && <div className="invalid-feedback">{errors.phone_no}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Website</label>
                            <input
                                type="text"
                                name="website"
                                className={`form-control ${errors.website ? 'is-invalid' : ''}`}
                                value={user.website || ''}
                                onChange={handleChange}
                            />
                            {errors.website && <div className="invalid-feedback">{errors.website}</div>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Gender</label>
                            <select
                                name="gender"
                                className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                                value={user.gender || ''}
                                onChange={handleChange}
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option>
                            </select>
                            {errors.gender && <div className="invalid-feedback">{errors.gender}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Date of Birth</label>
                            <input
                                type="date"
                                name="date_of_birth"
                                className={`form-control ${errors.date_of_birth ? 'is-invalid' : ''}`}
                                value={user.date_of_birth || ''}
                                onChange={handleChange}
                            />
                            {errors.date_of_birth && <div className="invalid-feedback">{errors.date_of_birth}</div>}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <button 
                        className="btn btn-primary" 
                        onClick={nextStep} 
                        disabled={!isValid()}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserInfo;
