import React, { useState } from 'react';
import api from '../../api/api';
import { toast } from 'react-hot-toast';

const ApplyCouponForm = ({ setPromotion }) => {
  const [couponCode, setCouponCode] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await api.get(`/promotion/${couponCode}`);
      // setMessage(response.data.success);
      setPromotion(response.data)
      toast.success(response.data.success)
      // Handle the discount response.data.discount as needed
    } catch (error) {
      // setMessage(error.response.data.error);
      toast.error(error.response.data.error)
    }
  };

  return (
    <form method="post" className="apply-coupon" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Enter Coupon Code..."
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value)}
        required
      />
      <button className="btn btn-md" style={{fontSize: "14px"}} type="submit">Apply Coupon</button>
      {/* {message && <p>{message}</p>} */}
    </form>
  );
};

export default ApplyCouponForm;
