import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCategories} from '../../redux/slices/categoriesSlice';
import {setCategory, fetchProductsByCategory } from './../../redux/slices/filterProductSlice';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories, loading, error } = useSelector((state) => state.categories);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleCategoryClick = (category) => {
    navigate(`/products/${category}`);
  };

  const handleMouseEnter = (category) => {
    setHoveredCategory(category);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
  };

  const renderSubCategories = (parentCategory) => {
    const subCategories = categories.filter(cat => cat.parent_category_id === parentCategory.id);
    if (subCategories.length === 0) {
      return <div className="no-subcategories">No subcategories</div>;
    }
    return (
      <ul className="subcategories-list">
        {subCategories.map((subCategory) => (
          <li key={subCategory.id}>
            <a onClick={() => handleCategoryClick(subCategory.category_name)}>
              {subCategory.category_name}
            </a>
          </li>
        ))}
      </ul>
    );
  };


  return (
    <section className="home-slider position-relative mb-30">
    <div className="container">
      <div className="row">
      <div className="col-lg-2 d-none d-lg-flex">
      <div className="categories-dropdown-wrap style-2 font-heading mt-30">
        <div className="d-flex categori-dropdown-inner">
          <ul className="categories-list">
            {categories.filter(cat => !cat.parent_category_id).map((category) => (
              <li
                key={category.id}
                onMouseEnter={() => handleMouseEnter(category)}
                onMouseLeave={handleMouseLeave}
                className="category-item w-100"
              >
                <a onClick={() => handleCategoryClick(category.category_name)}>
                  <img src="assets/imgs/theme/icons/category-1.svg" alt="" />
                  {category.category_name}
                </a>
                {hoveredCategory && hoveredCategory.id === category.id && (
                  <div className="subcategories-popup">
                    {renderSubCategories(category)}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
        <div className="col-lg-7">
          <div className="home-slide-cover mt-30">
            <div className="hero-slider-1 style-5 dot-style-1 dot-style-1-position-2">
              <div className="single-hero-slider single-animation-wrap border" style={{ backgroundImage: "url(assets/imgs/promotions/13.png)" }}>
                <div className="slider-content">
                  <h1 className="display-2 mb-40">
                    Don’t miss amazing<br />
                    grocery deals
                  </h1>
                  <p className="mb-65">Sign up for the daily newsletter</p>
                  <form className="form-subcriber d-flex">
                    <input type="email" placeholder="Your email address" />
                    <button className="btn" type="submit">Subscribe</button>
                  </form>
                </div>
              </div>
              {/* <div className="single-hero-slider single-animation-wrap" style={{ backgroundImage: "url(assets/imgs/slider/slider-8.png)" }}>
                <div className="slider-content">
                  <h1 className="display-2 mb-40">
                    Fresh Vegetables<br />
                    Big discount
                  </h1>
                  <p className="mb-65">Save up to 50% off on your first order</p>
                  <form className="form-subcriber d-flex">
                    <input type="email" placeholder="Your email address" />
                    <button className="btn" type="submit">Subscribe</button>
                  </form>
                </div>
              </div> */}
            </div>
            <div className="slider-arrow hero-slider-1-arrow"></div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="row">
            <div className="col-md-6 col-lg-12">
              <div className="banner-img style-4 border mt-30">
                <img src="assets/imgs/promotions/1.png" alt="" />
                <div className="banner-text">
                  <h4 className="mb-30">
                    Everyday Fresh &amp; <br />Clean with Our<br />
                    Products
                  </h4>
                  <a href="shop-grid-right.html" className="btn btn-xs mb-50">Shop Now <i className="fi-rs-arrow-small-right"></i></a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-12">
              <div className="banner-img style-5 border mt-5 mt-md-30">
                <img src="assets/imgs/promotions/4.png" alt="" />
                <div className="banner-text">
                  <h5 className="mb-20">
                    The best Organic <br />
                    Products Online
                  </h5>
                  <a href="shop-grid-right.html" className="btn btn-xs">Shop Now <i className="fi-rs-arrow-small-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default Sidebar;

  