import React from 'react'

export default function VendorInfo({ vendor_shop }) {
  return (
    <>
      <div className="tab-pane fade" id="Vendor-info">
        <div className="vendor-logo d-flex mb-30">
          <img src={`${process.env.PUBLIC_URL}/assets/imgs/vendor/vendor-18.svg`} />
          <div className="vendor-name ml-15">
            <h6>
              <a href="vendor-details-2.html">{vendor_shop.shopname}</a>
            </h6>
            <div className="product-rate-cover text-end">
              <div className="product-rate d-inline-block">
                <div className="product-rating" style={{width: '90%'}} />
              </div>
              <span className="font-small ml-5 text-muted"> (32 reviews)</span>
            </div>
          </div>
        </div>
        <ul className="contact-infor mb-50">
          <li><i className="fi fi-rs-diploma mr-10 text-brand"></i><strong>Address: </strong> <span>{vendor_shop.location}</span></li>
          <li><i className="fi fi-rs-diploma mr-10 text-brand"></i><strong>Contact Seller:</strong><span>{vendor_shop.contact ? vendor_shop.contact : "(+91) - 540-025-553"}</span></li>

          {/* <li><img src={`http://localhost:3000/assets/imgs/theme/icons/icon-location.svg`} /><strong>Address: </strong> <span>{vendor_shop.location}</span></li>
          <li><img src="http://localhost:3000/assets/imgs/theme/icons/icon-contact.svg" /><strong>Contact Seller:</strong><span>{vendor_shop.contact ? vendor_shop.contact : "(+91) - 540-025-553"}</span></li> */}
        </ul>
        <div className="d-flex mb-55">
          <div className="mr-30">
            <p className="text-brand font-xs">Rating</p>
            <h4 className="mb-0">92%</h4>
          </div>
          <div className="mr-30">
            <p className="text-brand font-xs">Ship on time</p>
            <h4 className="mb-0">100%</h4>
          </div>
          <div>
            <p className="text-brand font-xs">Chat response</p>
            <h4 className="mb-0">89%</h4>
          </div>
        </div>
        <p>Noodles &amp; Company is an American fast-casual restaurant that offers international and American noodle dishes and pasta in addition to soups and salads. Noodles &amp; Company was founded in 1995 by Aaron Kennedy and is headquartered in Broomfield, Colorado. The company went public in 2013 and recorded a $457 million revenue in 2017.In late 2018, there were 460 Noodles &amp; Company locations across 29 states and Washington, D.C.</p>
      </div>
    </>
  )
}
