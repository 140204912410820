import {React, useEffect, useState} from 'react';
import ModelView from '../ModelView';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../redux/slices/productSlice';
import { ProductInfo } from '../product/ProductInfo';

export const PopularProducts = ({productShowcaseName}) => {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector(state => state.products)
  const [showModel, setShowModel] = useState(false)

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);
  

  return (
    <>
      <section className="product-tabs section-padding position-relative">
        <div className="container">
            <div className="section-title style-2 wow animate__animated animate__fadeIn">
                <h3>{productShowcaseName}</h3>
                {/* <ul className="nav nav-tabs links" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="nav-tab-one" data-bs-toggle="tab" data-bs-target="#tab-one" type="button" role="tab" aria-controls="tab-one" aria-selected="true">All</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="nav-tab-two" data-bs-toggle="tab" data-bs-target="#tab-two" type="button" role="tab" aria-controls="tab-two" aria-selected="false">Milks & Dairies</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="nav-tab-three" data-bs-toggle="tab" data-bs-target="#tab-three" type="button" role="tab" aria-controls="tab-three" aria-selected="false">Coffes & Teas</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="nav-tab-four" data-bs-toggle="tab" data-bs-target="#tab-four" type="button" role="tab" aria-controls="tab-four" aria-selected="false">Pet Foods</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="nav-tab-five" data-bs-toggle="tab" data-bs-target="#tab-five" type="button" role="tab" aria-controls="tab-five" aria-selected="false">Meats</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="nav-tab-six" data-bs-toggle="tab" data-bs-target="#tab-six" type="button" role="tab" aria-controls="tab-six" aria-selected="false">Vegetables</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="nav-tab-seven" data-bs-toggle="tab" data-bs-target="#tab-seven" type="button" role="tab" aria-controls="tab-seven" aria-selected="false">Fruits</button>
                    </li>
                </ul> */}
            </div>
            {/* <!--End nav-tabs-->  */}
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                    <div className="row product-grid-4">

                       {products.map(product => {
                        return <ProductInfo key={product.id} product={product} showModel={showModel} setShowModel={setShowModel} />
                       })}
                             
                    </div>
                    {/* <!--End product-grid-4-->  */}
                </div>
                {/* <!--En tab one-->  */}
               
              
            </div>
            {/* <!--End tab-content-->  */}
        </div>
        </section>
        {showModel && <ModelView data={showModel} setData={setShowModel} />}
    </>
  )
}