import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from "../../api/api";

// Define initial state
const initialState = {
  vendorShops: [],
  loading: false,
  error: null,
};

// Define async thunk for fetching vendor shops
export const fetchVendorShops = createAsyncThunk(
  'vendorShops/fetchVendorShops',
  async () => {
    try {
      const response = await api.get('/vendor-shops');
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create slice using createSlice
const vendorShopsSlice = createSlice({
  name: 'vendorShops',
  initialState,
  reducers: {
    // Add reducers if needed
  },
  extraReducers: builder => {
    builder
      .addCase(fetchVendorShops.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVendorShops.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.vendorShops = action.payload;
      })
      .addCase(fetchVendorShops.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { } = vendorShopsSlice.actions; // Add actions if needed
export default vendorShopsSlice.reducer;
