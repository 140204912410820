import React from 'react'
import { Model } from './../Model';
import { QuickView } from './../QuickView';
import { Header } from './Header';

export default function Navbar() {
  return (
    <>
      <Model />
      <QuickView />
      <Header />
    </>
  )
}
