import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Invoice() {
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const todayDate = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    const storedData = localStorage.getItem('userOrders');

    if (storedData) {
      const existingOrders = JSON.parse(storedData);
      if (existingOrders.order) {
        setOrder(existingOrders.order);
        console.table('Retrieved Order:', existingOrders.order);
      } else {
        navigate('/cart');
      }
    } else {
      console.log('No order found in localStorage.');
      navigate('/cart');
    }
  }, [navigate]);

  if (!order) {
    return <div>Loading...</div>; // Display a loading message or spinner while waiting for order data
  }

  return (
    <>
      <div className="invoice invoice-content invoice-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="invoice-inner">
                <div className="invoice-info" id="invoice_wrapper">
                  <div className="invoice-header">
                    <div className="invoice-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-invoice.svg`} className="img-fluid" alt="invoice icon" />
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="logo">
                          <a href="index.html" className="mr-20">
                            <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/logo.svg`} alt="logo" />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <h2 className="mb-0">INVOICE</h2>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="text">
                          {order.address ? `${order.address.address_line_1}, ${order.address.zip_code}` : 'Address not available'} <br />
                          {order.address ? `${order.address.city}, ${order.address.state}` : 'City and State not available'} <br />
                          <abbr title="Phone">Phone:</abbr> {order.address ? order.address.contact_no : 'Contact number not available'}<br />
                        </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <strong className="text-brand">{order.user ? order.user.fname + " " + order.user.lname : 'Name not available'}</strong> <br />
                        <abbr title="Email">Email: </abbr> {order.user ? order.user.email : 'Email not available'}
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-12"><div className="hr mb-10" /></div>
                      <div className="col-lg-4">
                        <strong className="text-brand"> Invoice No:</strong> 789654
                      </div>
                      <div className="col-lg-4 text-center">
                        <strong className="text-brand"> Issue Date:</strong> {todayDate}
                      </div>
                      <div className="col-lg-4 text-end">
                        <strong className="text-brand"> Payment:</strong> Paid
                      </div>
                      <div className="col-12"><div className="hr mt-10" /></div>
                    </div>
                  </div>
                  <div className="invoice-center">
                    <div className="table-responsive">
                      <table className="table table-striped invoice-table">
                        <thead className="bg-active">
                          <tr>
                            <th>Item</th>
                            <th className="text-center">Unit Price</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.cartItems && order.cartItems.length > 0 ? (
                            order.cartItems.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="item-desc-1">
                                    <span>{item.product_name}</span>
                                    <small>SKU: {item.sku}</small>
                                  </div>
                                </td>
                                <td className="text-center">${item.price}</td>
                                <td className="text-center">{item.quantity}</td>
                                <td className="text-right">${(item.price * item.quantity).toFixed(2)}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} className="text-center">No items found</td>
                            </tr>
                          )}
                          <tr>
                            <td colSpan={3} className="text-end f-w-600">Subtotal</td>
                            <td className="text-right">${order.subtotal ? order.subtotal : 0}</td>
                          </tr>
                          <tr>
                            <td colSpan={3} className="text-end f-w-600">Shipping</td>
                            <td className="text-right">$10.60</td>
                          </tr>
                          <tr>
                            <td colSpan={3} className="text-end f-w-600">Tax</td>
                            <td className="text-right">$85.99</td>
                          </tr>
                          <tr>
                            <td colSpan={3} className="text-end f-w-600">Grand Total</td>
                            <td className="text-right f-w-600">${order.total ? order.total : 0}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {order.additional_info && <p className="mb-0 text-muted text-center"><strong>Customer Additional Info:</strong> {order.additional_info}.</p>}

                  <div className="invoice-bottom pb-80">
                    <div className="row">
                      <div className="col-md-6">
                        <h6 className="mb-15">Payment</h6>
                        <p className="font-sm text-brand">
                          <strong>Payment Status:</strong> Completed<br />
                          <strong>Payment Method:</strong> Paid by {order.transactionDetails ? order.transactionDetails.payment_method : 'Payment method not available'}<br />
                          <strong>Transaction Id:</strong> {order.transactionDetails ? order.transactionDetails.transaction_id : 'Transaction ID not available'}<br />
                        </p>
                      </div>
                      <div className="col-md-6 text-end">
                        <h6 className="mb-15">Total Amount</h6>
                        <h3 className="mt-0 mb-0 text-brand">${order.total ? order.total : 0}</h3>
                        <p className="mb-0 text-muted">Taxes Included</p>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-bottom">
                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <h3 className="invoice-title-1">Important Note</h3>
                          <ul className="important-notes-list-1">
                            <li>All amounts shown on this invoice are in US dollars</li>
                            <li>Finance charge of 1.5% will be made on unpaid balances after 30 days.</li>
                            <li>Once order done, money can't refund</li>
                            <li>Delivery might delay due to some external dependency</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-6 col-offsite">
                        <div className="text-end">
                          <p className="mb-0 text-13">Thank you for your business</p>
                          <p><strong>Nidus Cart</strong></p>
                          <div className="mobile-social-icon mt-50 print-hide">
                            <h6>Follow Us</h6>
                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-facebook-white.svg`} alt="facebook" /></a>
                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-twitter-white.svg`} alt="twitter" /></a>
                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-instagram-white.svg`} alt="instagram" /></a>
                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-pinterest-white.svg`} alt="pinterest" /></a>
                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-youtube-white.svg`} alt="youtube" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row text-center">
                      <div className="col-12">
                        <div className="hr mt-30 mb-30"></div>
                        <p className="mb-0 text-muted"><strong>Note:</strong> This is a computer-generated receipt and does not require a physical signature.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="invoice-btn-section clearfix d-print-none">
                  <a href="javascript:window.print()" className="btn btn-lg btn-custom btn-print hover-up">
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-print.svg`} alt="print" /> Print
                  </a>
                  <a id="invoice_download_btn" className="btn btn-lg btn-custom btn-download hover-up">
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-download.svg`} alt="download" /> Download
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
