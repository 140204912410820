import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ImageSlider from '../ecommerce/ImageSlider';
import { addToCart, updateCartQuantity } from '../redux/slices/cartSlice';
import { useDispatch, useSelector  } from 'react-redux';
import ProductDescription from '../ecommerce/product/ProductDescription';
import ProductAdditionalInfo from '../ecommerce/product/ProductAdditionalInfo';
import VendorInfo from '../ecommerce/product/VendorInfo';
import Review from '../ecommerce/product/Review';
import ReletedProducts from '../ecommerce/product/ReletedProducts';

export default function Product() {
  const dispatch = useDispatch();
  const location = useLocation();
  const product = location.state?.product;
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(product.price);
  const [selectedVariations, setSelectedVariations] = useState({});

  // const handleOptionClick = (variationName, optionValue, selectedOptionPrice) => {
  //   setSelectedVariations(prevState => ({
  //       ...prevState,
  //       [variationName]: optionValue
  //   }));
  //   setPrice(selectedOptionPrice);
  //   console.log(selectedOptionPrice);
  // };

  useEffect(() => {
    if (product) {
      dispatch(updateCartQuantity({ id: product.id, quantity }));
    }
  }, [quantity, dispatch, product.id]);


  let variations = product.variations;

  console.log('Type of variations:', typeof variations);

  try {
    // Step 2: If it's a string, try to parse it
    if (typeof variations === 'string') {
      variations = JSON.parse(variations);
  
      // Step 3: If it's still a string after parsing, parse again (handle double-encoded case)
      if (typeof variations === 'string') {
        variations = JSON.parse(variations);
      }
    }
  } catch (error) {
    console.error('Error parsing variations:', error);
    variations = []; // Fallback to an empty array in case of error
  }

  // Step 2: Ensure variations is an array
const validVariations = Array.isArray(variations) ? variations : [];

// Log valid variations to verify correct parsing
console.log('Valid Variations:', validVariations);

const handleOptionClick = (variationName, optionValue, optionPrice) => {
  setSelectedVariations(prev => ({
    ...prev,
    [variationName]: optionValue,
  }));
  setPrice(optionPrice);
};

const colorBgStyle = (variationName, optionValue) => {
  if (variationName.toLowerCase() === 'color') {
    return { backgroundColor: optionValue };
  }
  return {};
};
  




  const handleAddToCart = () => {
    if (product) {
      dispatch(addToCart({ ...product, quantity, selectedVariations }));
      navigate('/cart');
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1)
  };
  const decreaseQuantity = () => {
    quantity > 1 && setQuantity(quantity - 1)
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  // const colorBgStyle = (variationName, color) => {
  //   if (variationName.toLowerCase() === 'color') {
  //     return {
  //       backgroundColor: color,
  //       width: '22px',
  //       height: '22px',
  //       border: '1px solid #ccc'
  //     };
  //   }
  //   return {};
  // };


  return (
    <>
      {/* {console.log(parsedVariations)} */}
      {/* {console.log(product)} */}
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="index.html" rel="nofollow"><i className="fi-rs-home mr-5" />Home</a>
            <span /> <a href="shop-grid-right.html">{product.category.category_name}</a> <span /> {product.product_name}
          </div>
        </div>
      </div>
      <div className="container mb-30" style={{transform: 'none'}}>
        <div className="row" style={{transform: 'none'}}>
          <div className="col-xl-11 col-lg-12 m-auto" style={{transform: 'none'}}>
            <div className="row" style={{transform: 'none'}}>
              <div className="col-xl-9">
                <div className="product-detail accordion-detail">
                  <div className="row mb-50 mt-30">
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    {/* start gallery */}
                    <ImageSlider images={product.images} />
                    {/* End Gallery */}

                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="detail-info pr-30 pl-30">
                        <span className="stock-status out-stock"> Sale Off </span>
                        <h2 className="title-detail fs-4">{product.product_name}</h2>
                        <div className="product-detail-rating">
                          <div className="product-rate-cover text-end">
                            <div className="product-rate d-inline-block">
                              <div className="product-rating" style={{width: '90%'}} />
                            </div>
                            <span className="font-small ml-5 text-muted"> ({product.reviews.length})</span>
                          </div>
                        </div>
                        <div className="clearfix product-price-cover">
                          <div className="product-price primary-color float-left">
                            <span className="current-price text-brand">${price}</span>
                            <span>
                              <span className="save-price font-md color3 ml-15">26% Off</span>
                              <span className="old-price font-md ml-15">$52</span>
                            </span>
                          </div>
                        </div>
                        <div className="short-desc mb-30">
                          {/* <div className="font-lg" dangerouslySetInnerHTML={{ __html: product.description }} /> */}
                          <p className="font-lg">{product.short_description}</p>
                        </div>
                        {/* <div>
                        {product.variations.map((variation, index) => (
                              variation.options.length > 0 && (
                                <div key={index} className="attr-detail attr-size mb-30">
                                  <strong className="mr-10">{variation.name}: </strong>
                                  <ul className="list-filter size-filter font-small">
                                    {variation.options.map((option, idx) => {
                                      const isSelected = selectedVariations[variation.name] === option.value;
                                      const buttonStyle = colorBgStyle(variation.name, option.value);
                                      return (
                                        <li key={idx} className={`active mx-1 mb-1 ${isSelected ? 'selected' : ''} mx-2`}>
                                          <button
                                            className={`rounded ${isSelected ? 'border-danger' : 'border'}`}
                                            style={buttonStyle}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleOptionClick(variation.name, option.value, option.descriptions[0].price);
                                            }}
                                          >
                                            {variation.name.toLowerCase() !== 'color' && option.value}
                                          </button> <br/>
                                          <span className={`${isSelected && 'text-danger'}`}>{variation.name.toLowerCase() == 'color' && option.value}</span>
                                        </li>
                                      );
                                    })}
                              </ul>
                            </div>
                            )
                          ))}
                        </div> */}
                          
                         
                        <div className="variation-container">
                            {validVariations.length > 0 ? (
                              validVariations.map((variation, index) => (
                                <div key={index} className="attr-detail attr-size mb-30">
                                  <strong className="mr-10">{variation.variation}: </strong>
                                  <ul className="list-filter size-filter font-small">
                                    {variation.options.map((option, idx) => {
                                      const isSelected = selectedVariations[variation.variation] === option.option;
                                      const buttonStyle = colorBgStyle(variation.variation, option.option);

                                      return (
                                        <li key={idx} className={`option-item ${isSelected ? 'selected' : ''}`}>
                                          <button
                                            className={`option-button ${isSelected ? 'selected' : ''}`}
                                            style={buttonStyle}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleOptionClick(variation.variation, option.option, option.price);
                                            }}
                                          >
                                            {variation.variation.toLowerCase() !== 'color' && option.option}
                                          </button>
                                          {variation.variation.toLowerCase() === 'color' && (
                                            <span className="color-label">{option.option}</span>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              ))
                            ) : (
                              <div>No variations available</div>
                            )}

                            {/* {price && <div className="price-display">Selected Price: ${price}</div>} */}
                          </div>
                          <div className='my-2'>
                            <p>{product.stock && <small className="my-5">Stock: <span className="text-brand">{product.stock} Items In Stock</span></small>}</p>
                          </div>
                        
                        <div className="detail-extralink mb-50">
                          <div className="detail-qty border radius">
                            <a className="qty-down" onClick={decreaseQuantity}><i className="fi-rs-angle-small-down" /></a>
                            <input type="text" name="quantity" className="qty-val" value={quantity && quantity? quantity: 1} readOnly />
                          
                            <a className="qty-up" onClick={increaseQuantity}><i className="fi-rs-angle-small-up" /></a>
                          </div>
                          <div className="product-extra-link2">
                            <button type="submit" className="button button-add-to-cart" disabled={!product.stock} onClick={handleAddToCart}><i className="fi-rs-shopping-cart"  /> {!product.stock ? 'Out of Stock' : 'Add to cart'}</button>
                            {/* <a aria-label="Add To Wishlist" className="action-btn hover-up" href="shop-wishlist.html"><i className="fi-rs-heart" /></a>
                            <a aria-label="Compare" className="action-btn hover-up"><i className="fi-rs-shuffle" /></a> */}
                          </div>
                        </div>
                        <div className="font-xs">
                        
                          <ul className="mr-50 float-start">
                            {product.brand && <li className="mb-5">Brand: <span className="text-brand">{product.brand.brand_name}</span></li>}
                            {product.model && <li className="mb-5">Model: <span className="text-brand">{product.model.model_name}</span></li>}
                          {(product.width && product.height) && <li className="mb-5">Measurement: <span className="text-brand">{product.width} x {product.height} x {product.length}</span></li>}

                          </ul>
                          <ul className="float-start">
                          {product.sku && <li className="mb-5">SKU: <span className="text-brand">{product.sku}</span></li>}
                          {product.weight_name && <li className="mb-5">Weight: <span className="text-brand">{product.weight_value} {product.weight_name}</span></li>}

                          
                          </ul>
                        </div>
                      </div>
                      {/* Detail Info */}
                    </div>
                  </div>
                  <div className="product-info">
                    <div className="tab-style3">
                      <ul className="nav nav-tabs text-uppercase">
                        <li className="nav-item">
                          <a className="nav-link active" id="Description-tab" data-bs-toggle="tab" href="#Description">Description</a>
                        </li>
                        {/* <li className="nav-item">
                          <a className="nav-link" id="Additional-info-tab" data-bs-toggle="tab" href="#Additional-info">Additional info</a>
                        </li> */}
                        <li className="nav-item">
                          <a className="nav-link" id="Vendor-info-tab" data-bs-toggle="tab" href="#Vendor-info">Vendor</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="Reviews-tab" data-bs-toggle="tab" href="#Reviews">Reviews ({product.reviews.length})</a>
                        </li>
                      </ul>
                      <div className="tab-content shop_info_tab entry-main-content">
                        <ProductDescription descrtipion={product.description} />
                        {/* <ProductAdditionalInfo /> */}
                        <VendorInfo vendor_shop={product.vendor_shop} />
                        <Review reviews={product.reviews} />
                      </div>
                    </div>
                  </div>
                  <ReletedProducts productCategory={product.category.category_name} />
                </div>
              </div>
              <div className="col-xl-3 primary-sidebar sticky-sidebar mt-30" style={{position: 'relative', overflow: 'visible', boxSizing: 'border-box', minHeight: 1}}>
                {/* Fillter By Price */}
                {/* Product sidebar Widget */}
                <div className="theiaStickySidebar" style={{paddingTop: 0, paddingBottom: 1, position: 'static', transform: 'none', top: 0, left: '1332.44px'}}><div className="sidebar-widget widget-delivery mb-30 bg-grey-9 box-shadow-none">
                    {/* <h5 className="section-title style-3 mb-20">Delivery</h5>
                    <ul>
                      <li>
                        <i className="fi fi-rs-marker mr-10 text-brand" />
                        <span>
                          5171 W Campbell Ave <br />
                          undefined Kent, Utah 53127 <br />United States
                        </span>
                        <a href="#" className="change float-end">Change</a>
                      </li>
                      <li className="hr"><span /></li>
                    </ul> */}
                    <h5 className="section-title style-3 mb-20">Return &amp; Warranty</h5>
                    <ul>
                      <li>
                        <i className="fi fi-rs-shield-check mr-10 text-brand" />
                        <span> 100% Authentic </span>
                      </li>
                      <li>
                        <i className="fi fi-rs-time-forward-ten mr-10 text-brand" />
                        <span> 10 Days Return </span>
                      </li>
                      <li>
                        <i className="fi fi-rs-diploma mr-10 text-brand" />
                        <span> 12 Months Warranty </span>
                      </li>
                    </ul>
                  </div><div className="sidebar-widget widget-vendor mb-30 bg-grey-9 box-shadow-none">
                    <h5 className="section-title style-3 mb-20">Vendor</h5>
                    <div className="vendor-logo d-flex mb-30">
                      <img src={`${process.env.PUBLIC_URL}/assets/imgs/vendor/vendor-18.svg`} />
                      <div className="vendor-name ml-15">
                        <h6>
                          <a href="vendor-details-2.html">{product.vendor_shop.shopname}</a>
                        </h6>
                        <div className="product-rate-cover text-end">
                          <div className="product-rate d-inline-block">
                            <div className="product-rating" style={{width: '90%'}} />
                          </div>
                          <span className="font-small ml-5 text-muted"> (32 reviews)</span>
                        </div>
                      </div>
                    </div>
                    <ul className="contact-infor">
                      <li><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-location.svg`} /><strong>Address: </strong> <span>{product.vendor_shop.location}</span></li>
                      <li><img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/icon-contact.svg`} /><strong>Contact Seller:</strong><span>{product.vendor_shop.contact ? product.vendor_shop.contact : "(+91) - 540-025-553"}</span></li>
                      <li className="hr"><span /></li>
                    </ul>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="text-brand font-xs">Rating</p>
                        <h4 className="mb-0">92%</h4>
                      </div>
                      <div>
                        <p className="text-brand font-xs">Ship on time</p>
                        <h4 className="mb-0">100%</h4>
                      </div>
                      <div>
                        <p className="text-brand font-xs">Chat response</p>
                        <h4 className="mb-0">89%</h4>
                      </div>
                    </div>
                    <ul>
                      <li className="hr"><span /></li>
                    </ul>
                    <p>Become a Vendor? <Link to='/vendor-registration'>Register now</Link></p>
                  </div>
                  
                  </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
