import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProducts } from '../redux/slices/productSlice';

export const LiveSearch = () => {
  const [query, setQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { products } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (query.length > 0) {
      const results = products.filter((product) =>
        product.product_name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(results);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  }, [query, products]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleItemClick = () => {
    setShowDropdown(false);
    setQuery('');
  };

  return (
    <div className="search-style-2">
     <form action="#">
      <input
        type="text"
        placeholder="Search for items..."
        value={query}
        onChange={handleInputChange}
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setTimeout(() => setShowDropdown(false), 100)}
        className="form-control"
      />
      </form>
      {showDropdown && (
        <ul className="list-group search-dropdown">
          {filteredProducts.slice(0, 10).map((product) => (
            <li key={product.id} className="list-group-item">
              <Link
                to={`/product/${product.id}`}
                state={{ product }} // Pass product data through state
                className="d-block text-decoration-none"
                onClick={handleItemClick}
              >
                {product.product_name}
              </Link>
            </li>
          ))}
          {filteredProducts.length === 0 && (
            <li className="list-group-item text-muted">No results found</li>
          )}
        </ul>
      )}
    </div>
  );
};
