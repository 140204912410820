import React, { useState } from 'react';

const ShopPolicies = ({ policies, setPolicies, nextStep, prevStep }) => {
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPolicies({ ...policies, [name]: value });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';

        if (!value) {
            error = 'This field is required';
        } else if (isNaN(value) || value < 0) {
            error = 'Value must be a positive number';
        }

        setErrors({ ...errors, [name]: error });
    };

    const isValid = () => {
        return !Object.values(errors).some(error => error !== '') &&
               policies.product_tax_percent &&
               policies.product_vat_percent;
    };

    return (
      <div className='row justify-content-center m-5'>
          <div className='col-md-6 card shadow p-4 mb-5 bg-body rounded'>
            <h2>Shop Policies</h2>
            <div className="row">
                {/* <div className="col-md-6">
                    <div className="form-group">
                        <label>Sell Commission Percentage (%)</label>
                        <input
                            type="text"
                            name="sell_commission_percentage"
                            className={`form-control ${errors.sell_commission_percentage ? 'is-invalid' : ''}`}
                            value={policies.sell_commission_percentage || ''}
                            onChange={handleChange}
                        />
                        {errors.sell_commission_percentage && <div className="invalid-feedback">{errors.sell_commission_percentage}</div>}
                    </div>
                </div> */}
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Product Tax Percentage (%)</label>
                        <input
                            type="text"
                            name="product_tax_percent"
                            className={`form-control ${errors.product_tax_percent ? 'is-invalid' : ''}`}
                            value={policies.product_tax_percent || ''}
                            onChange={handleChange}
                        />
                        {errors.product_tax_percent && <div className="invalid-feedback">{errors.product_tax_percent}</div>}
                    </div>
                </div>
              <div className="col-md-6">
                  <div className="form-group">
                      <label>Product VAT Percentage (%)</label>
                      <input
                          type="text"
                          name="product_vat_percent"
                          className={`form-control ${errors.product_vat_percent ? 'is-invalid' : ''}`}
                          value={policies.product_vat_percent || ''}
                          onChange={handleChange}
                      />
                      {errors.product_vat_percent && <div className="invalid-feedback">{errors.product_vat_percent}</div>}
                </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Back
                </button>
                <button 
                    className="btn btn-primary" 
                    onClick={nextStep} 
                    disabled={!isValid()}>
                    Next
                </button>
            </div>
          </div>
        </div>
        </div>
    );
};

export default ShopPolicies;
