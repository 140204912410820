import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShopProducts } from '../redux/slices/shopProductsSlice';
import { useParams } from 'react-router-dom';
import { ProductInfo } from '../ecommerce/product/ProductInfo';


export default function VendorShop({ shopName }) {
  const dispatch = useDispatch();
  const { shopname } = useParams();
  const { shopProducts, loading, error } = useSelector(state => state.shopProducts);
  const [showModel, setShowModel] = useState(false)

  useEffect(() => {
    dispatch(fetchShopProducts(shopname));
  }, [dispatch, shopname]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  const baseUrl = `${protocol}//${hostname}${port}`;

  return (
    <>
      {shopProducts[0] ? (<div>
        {/* {console.log(shopProducts[0])} */}
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="index.html" rel="nofollow"><i className="fi-rs-home mr-5" />Home</a>
              <span /> Store <span /> {shopProducts[0].shopname}
            </div>
          </div>
        </div>
        <div className="container mb-30">
          {/* <div className="archive-header-3 mt-30 mb-80" style={{backgroundImage: `url(http://127.0.0.1:8000/${shopProducts[0].banner_image.image_name})`}}> */}
          <div className="archive-header-3 mt-30 mb-80" style={{backgroundColor: "#F1FCFC", color: "balck"}}>
            <div className="archive-header-3-inner">
              <div className="vendor-logo mr-50">
                <img src={`http://127.0.0.1:8000/${shopProducts[0].logo_image.image_name}`} alt='shop-logo'/>
              </div>
              <div className="vendor-content">
                <div className="product-category">
                  <span className="text-muted">Since 2012</span>
                </div>
                <h3 className="mb-5 text-dark"><a href="vendor-details-1.html" className="text-dark">{shopProducts[0].shopname}</a></h3>
                <div className="product-rate-cover mb-15">
                  <div className="product-rate d-inline-block">
                    <div className="product-rating" style={{width: '90%'}} />
                  </div>
                  <span className="font-small ml-5 text-muted"> (4.0)</span>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="vendor-des mb-15">
                      <p className="font-sm text-dark">{shopProducts[0].description}</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="vendor-info text-dark mb-15">
                      <ul className="font-sm">
                        <li><img className="mr-5" src={`${baseUrl}/assets/imgs/theme/icons/icon-location.svg`}/><strong>Address: </strong> <span>{shopProducts[0].location}</span></li>
                        <li><img className="mr-5" src={`${baseUrl}/assets/imgs/theme/icons/icon-contact.svg`}/><strong>Call Us:</strong><span>{shopProducts[0].contact? shopProducts[0].contact: "(+91) - 540-025-124553"}</span></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="follow-social">
                      <h6 className="mb-15 text-dark">Follow Us</h6>
                      <ul className="social-network">
                        <li className="hover-up">
                          <a href="#">
                            <img src={`${baseUrl}/assets/imgs/theme/icons/social-tw.svg`}/>
                          </a>
                        </li>
                        <li className="hover-up">
                          <a href="#">
                            <img src={`${baseUrl}/assets/imgs/theme/icons/social-fb.svg`}/>
                          </a>
                        </li>
                        <li className="hover-up">
                          <a href="#">
                            <img src={`${baseUrl}/assets/imgs/theme/icons/social-insta.svg`}/>
                          </a>
                        </li>
                        <li className="hover-up">
                          <a href="#">
                            <img src={`${baseUrl}/assets/imgs/theme/icons/social-pin.svg`}/>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div className="row flex-row-reverse" style={{transform: 'none'}}>
          <div className="col-lg-4-5">
            <div className="shop-product-fillter">
              <div className="totall-product">
                <p>We found <strong className="text-brand">{shopProducts[0].products.length}</strong> items for you!</p>
              </div>
              <div className="sort-by-product-area">
                <div className="sort-by-cover mr-10">
                  <div className="sort-by-product-wrap">
                    <div className="sort-by">
                      <span><i className="fi-rs-apps" />Show:</span>
                    </div>
                    <div className="sort-by-dropdown-wrap">
                      <span> 50 <i className="fi-rs-angle-small-down" /></span>
                    </div>
                  </div>
                  <div className="sort-by-dropdown">
                    <ul>
                      <li><a className="active" href="#">50</a></li>
                      <li><a href="#">100</a></li>
                      <li><a href="#">150</a></li>
                      <li><a href="#">200</a></li>
                      <li><a href="#">All</a></li>
                    </ul>
                  </div>
                </div>
                <div className="sort-by-cover">
                  <div className="sort-by-product-wrap">
                    <div className="sort-by">
                      <span><i className="fi-rs-apps-sort" />Sort by:</span>
                    </div>
                    <div className="sort-by-dropdown-wrap">
                      <span> Featured <i className="fi-rs-angle-small-down" /></span>
                    </div>
                  </div>
                  <div className="sort-by-dropdown">
                    <ul>
                      <li><a className="active" href="#">Featured</a></li>
                      <li><a href="#">Price: Low to High</a></li>
                      <li><a href="#">Price: High to Low</a></li>
                      <li><a href="#">Release Date</a></li>
                      <li><a href="#">Avg. Rating</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row product-grid">
              { }
              {!shopProducts[0].products.length? <h3 className='text-center mt-5 pt-5'>No products available, <br></br> Products will be added soon!</h3> :shopProducts[0].products.map(product => {
                return <ProductInfo key={product.id} product={product} showModel={showModel} setShowModel={setShowModel} />
              })}
            </div>
            {/*product grid*/}
            
            
          </div>
          <div className="col-lg-1-5 primary-sidebar sticky-sidebar" style={{position: 'relative', overflow: 'visible', boxSizing: 'border-box', minHeight: 1}}>
            {/* Fillter By Price */}
            <div className="theiaStickySidebar" style={{paddingTop: 0, paddingBottom: 1, position: 'static', transform: 'none'}}>
              
              
            
              <div className="banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none" style={{visibility: 'hidden', animationName: 'none'}}>
                <img src= {`${baseUrl}/assets/imgs/banner/banner-11.png`}/>
                <div className="banner-text">
                  <span>{shopProducts[0].shopname}</span>
                  <h4>
                    Save 17% <br />
                    on <span className="text-brand">{shopProducts[0].shopname}</span><br />
                    Products
                  </h4>
                </div>
              </div>
              </div>
              
          </div>
      </div>
        </div>
      </div>) : <><h3 className='text-center'>Shop doesn't exist</h3></>}
    </>
  )
}
