import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/slices/authSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast'; // Updated imports

export default function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, error } = useSelector((state) => state.auth);
  const [captchaValue, setCaptchaValue] = useState(null);

  const onSubmit = (data) => {
    if (captchaValue) {
      dispatch(loginUser(data));
    } else {
      alert('Please complete the CAPTCHA');
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    if (user) {
      toast.success('Logged in successfully!');
      setTimeout(() => {
        navigate('/dashboard');
      }, 1000); // Adjust the delay as needed
    }
  }, [user, navigate]);

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="row">
                <div className="col-lg-6 pr-30 d-none d-lg-block">
                  <img className="border-radius-15" src="assets/imgs/page/login-1.png" alt="Login" />
                </div>
                <div className="col-lg-6 col-md-8">
                  <div className="login_wrap widget-taber-content background-white">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h1 className="mb-5">Login</h1>
                        <p className="mb-30">Don't have an account? <Link to='/registration'>Registration</Link></p>
                        <div className={`alert alert-danger ${error ? "" : "d-none"}`} role="alert">
                          {error && error}
                        </div>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <input
                            type="text"
                            {...register('email', {
                              required: 'Email is required',
                              pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Invalid email address'
                              }
                            })}
                            name="email"
                            placeholder="Username or Email *"
                          />
                          {errors.email && <p className="error-message text-danger">{errors.email.message}</p>}
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            {...register('password', { required: 'Password is required' })}
                            name="password"
                            placeholder="Your password *"
                          />
                          {errors.password && <p className="error-message text-danger">{errors.password.message}</p>}
                        </div>

                        <div className="login_footer form-group mb-10">
                          <div className="chek-form">
                            <div className="custome-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="rememberMe"
                                id="exampleCheckbox1"
                                {...register('rememberMe')}
                              />
                              <label className="form-check-label" htmlFor="exampleCheckbox1"><span>Remember me</span></label>
                            </div>
                          </div>
                          <a className="text-muted" href="#">Forgot password?</a>
                        </div>

                        <ReCAPTCHA className='mb-10'
                          sitekey="6LefLgoqAAAAAIlzTe09KS0B_YNLdbaluqaowR3Y"
                          onChange={onCaptchaChange}
                        />

                        <div className="form-group">
                          <button type="submit" className="btn btn-heading btn-block hover-up" name="login" disabled={loading}>Log in</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
