import React from 'react'

export default function AccountDetails({ email, fname, lname, gender, contact }) {
  return (
    <>
      <div className="tab-pane fade" id="account-detail" role="tabpanel" aria-labelledby="account-detail-tab">
        <div className="card">
          <div className="card-header">
            <h5>Account Details</h5>
          </div>
          <div className="card-body">
            <p>Already have an account? <a href="page-login.html">Log in instead!</a></p>
            <form method="post" name="enq">
              <div className="row">
                <div className="form-group col-md-6">
                  <label>First Name <span className="required">*</span></label>
                  <input required className="form-control" value={fname} name="name" type="text" />
                </div>
                <div className="form-group col-md-6">
                  <label>Last Name <span className="required">*</span></label>
                  <input required className="form-control" value={lname} name="phone" />
                </div>
                <div className="form-group col-md-6">
                  <label>Display Name <span className="required">*</span></label>
                  <input required className="form-control" value={fname} name="dname" type="text" />
                </div>
                <div className="form-group col-md-6">
                  <label>Gender <span className="required">*</span></label>
                  <input required className="form-control" value={gender} name="dname" type="text" />
                </div>
                <div className="form-group col-md-6">
                  <label>Email Address <span className="required">*</span></label>
                  <input required className="form-control" value={email} name="email" type="email"/>
                </div>
                <div className="form-group col-md-6">
                  <label>Contact No <span className="required">*</span></label>
                  <input required className="form-control" value={contact} name="email" type="text"/>
                </div>
                <div className="form-group col-md-12">
                  <label>Current Password <span className="required">*</span></label>
                  <input required className="form-control" name="password" type="password" />
                </div>
                <div className="form-group col-md-12">
                  <label>New Password <span className="required">*</span></label>
                  <input required className="form-control" name="npassword" type="password" />
                </div>
                <div className="form-group col-md-12">
                  <label>Confirm Password <span className="required">*</span></label>
                  <input required className="form-control" name="cpassword" type="password" />
                </div>
                <div className="col-md-12">
                  <button type="submit" className="btn btn-fill-out submit font-weight-bold" name="submit" value="Submit">Save Change</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
