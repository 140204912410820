import React, { useState } from 'react';
import AddEditAddress from './AddEditAddress';

export default function Address({ address, def_address_id, onAddressChange }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  

  return (
    <div className='col-md-6' style={{ minHeight: "208px", marginTop: "15px" }}>
      <div className="d-flex flex-row mx-2 shadow-sm p-3 mb-5 bg-body-tertiary rounded" style={{ minHeight: "208px" }}>
        <div className="form-check" style={{ display: "inline-block" }}>
          <input
            className="form-check-input"
            type="radio"
            name="address_id"
            id={`billing_address_id_${address.id}`}
            value={address.id}
            checked={def_address_id === address.id}
            onChange={() => onAddressChange(address.id, address)}
          />
        </div>
        <div>
          <p>Address: {address.address_line_1}</p>
          <p>State: {address.state}</p>
          <p>City: {address.city}</p>
          <p>Zip Code: {address.zip_code}</p>
          <p>Contact: {address.contact_no}</p>
          <button className='btn btn-sm btn-primary' onClick={openModal}>Edit</button>
        </div>
      </div>

      <AddEditAddress address={address} modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </div>
  );
}

