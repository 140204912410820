// filterProductSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

// Async thunk to fetch products by category
export const fetchProductsByCategory = createAsyncThunk('filterProducts/fetchProductsByCategory', async (category) => {
  const response = await api.get(`/products/filterByCategory/${category}`);
  return response.data.data;
});

// Filter product slice
const filterProductSlice = createSlice({
  name: 'filterProducts',
  initialState: {
    products: [],
    loading: false,
    error: null,
    selectedCategory: '', // State property for selected category
  },
  reducers: {
    setCategory: (state, action) => {
      state.selectedCategory = action.payload; // Action to set selected category
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsByCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductsByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProductsByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCategory } = filterProductSlice.actions;

export const selectFilteredProducts = (state) => {
  return state.filterProducts.products;
};

export default filterProductSlice.reducer;
