import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCartQuantity } from '../redux/slices/cartSlice';

export default function CartItems({ item, removeCartItem }) {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(item.quantity);

  useEffect(() => {
    dispatch(updateCartQuantity({ id: item.id, quantity }));
  }, [quantity, dispatch, item.id]);

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => quantity > 1 && setQuantity(quantity - 1);

  return (
    <tr className="pt-30">
      <td className="custome-checkbox pl-30">
        <input className="form-check-input" type="checkbox" name="checkbox" id={`checkbox-${item.id}`} />
        <label className="form-check-label" htmlFor={`checkbox-${item.id}`} />
      </td>
      <td className="image product-thumbnail pt-40">
        <img className='mx-2' src={`http://127.0.0.1:8000${item.images[0].image_name}`} alt="#" />
      </td>
      <td className="product-des product-name">
        <h6 className="mb-5"><a className="product-name mb-10 text-heading" href="shop-product-right.html">{item.product_name}</a></h6>
        <div className="product-rate-cover">
          <div className="product-rate d-inline-block">
            <div className="product-rating" style={{ width: '90%' }}></div>
          </div>
          <span className="font-small ml-5 text-muted"> (4.0)</span>
        </div>
      </td>
      <td className="price" data-title="Price">
        <h4 className="text-body">${item.price}</h4>
      </td>
      <td className="text-center detail-info" data-title="Stock">
        <div className="detail-extralink mr-15">
          <div className="detail-qty border radius">
            <a className="qty-down" onClick={decreaseQuantity}><i className="fi-rs-angle-small-down" /></a>
            <input type="text" name="quantity" className="qty-val" value={quantity} readOnly />
            <a className="qty-up" onClick={increaseQuantity}><i className="fi-rs-angle-small-up" /></a>
          </div>
        </div>
      </td>
      <td className="price" data-title="Subtotal">
        <h4 className="text-brand">${(item.price * quantity).toFixed(2)}</h4>
      </td>
      <td className="action text-center" data-title="Remove">
        <a className="text-body" onClick={() => removeCartItem(item)}><i className="fi-rs-trash" /></a>
      </td>
    </tr>
  );
}
