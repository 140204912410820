import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser, clearUser } from '../../redux/slices/authSlice';

const Logout = ({ fromDashboard }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser());
      dispatch(clearUser());
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle logout error, if needed
    }
  };

  return (
    <a className={fromDashboard && "nav-link"} onClick={handleLogout}>
      {fromDashboard ? (
        <><i className="fi-rs-sign-out mr-10" />Logout</>
      ) : (
        <><i className="fi fi-rs-sign-out mr-10"></i>Sign out</>
      )}
    </a>
  );
  
};

export default Logout;
