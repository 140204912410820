import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./slices/productSlice";
import categoriesReducer from './slices/categoriesSlice';
import authReducer from './slices/authSlice';
import cartReducer from "./slices/cartSlice";
import filterProductReducer from "./slices/filterProductSlice";
import vendorShopsReducer from "./slices/vendorShopsSlice";
import shopProductsReducer from "./slices/shopProductsSlice";
import thunk from 'redux-thunk';

const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productReducer,
    categories: categoriesReducer,
    cart: cartReducer,
    filterProducts: filterProductReducer,
    vendorShops: vendorShopsReducer,
    shopProducts: shopProductsReducer,
  },
  // middleware: [thunk],
})

export default store;