import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { Toaster, toast } from 'react-hot-toast'; // Updated imports

// Set the app element for accessibility
Modal.setAppElement('#root');

export default function AddEditAddress({ address, modalIsOpen, closeModal }) {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      address_line_1: '',
      state: '',
      city: '',
      zip_code: '',
      contact_no: '',
    }
  });

  useEffect(() => {
    if (address) {
      for (const key in address) {
        setValue(key, address[key]);
      }
    }
  }, [address, setValue]);

  const onSubmit = (data) => {
    // Save to local storage
    // const userOrders = JSON.parse(localStorage.getItem('userOrders')) || [];
    // if (address) {
    //   userOrders.push({ address: { ...data, id: Date.now() } });
    // }
    // localStorage.setItem('userOrders', JSON.stringify(userOrders));
    // console.log(userOrders);

    const existingOrders = JSON.parse(localStorage.getItem('userOrders')) || {};
    existingOrders.order.address = data;
    localStorage.setItem('userOrders', JSON.stringify(existingOrders));
  
    // onSave(data);
    toast.success(`${address && address.id ? "Address updated" : "Address added"}`);
    closeModal();
  };
  

  const customStyles = {
    content: {
      maxWidth: '600px',
      maxHeight: 'fit-content',
      margin: 'auto'
    }
  };

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Edit Address" style={customStyles}>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <button 
              onClick={closeModal} 
              style={{ 
                position: 'absolute', 
                top: '10px', 
                right: '10px', 
                background: 'none', 
                border: 'none' 
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} className='text-danger fs-4' size="lg" />
            </button>
            <h2>{`${address ? "Edit Address": "Add New Address"} `}</h2>
            <form className='row justify-content-start' onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name='address_id' value={address?.id || ''} {...register('id')} />
              <div className="form-group col-md-12">
                <label htmlFor="addressLine1">Address Line 1</label>
                <input
                  type="text"
                  name="address_line_1"
                  {...register('address_line_1', { required: 'Address Line 1 is required' })}
                />
                {errors.address_line_1 && <span className="text-danger">{errors.address_line_1.message}</span>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  name="state"
                  {...register('state', { required: 'State is required' })}
                />
                {errors.state && <span className="text-danger">{errors.state.message}</span>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  {...register('city', { required: 'City is required' })}
                />
                {errors.city && <span className="text-danger">{errors.city.message}</span>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="zipCode">Zip Code</label>
                <input
                  type="text"
                  name="zip_code"
                  {...register('zip_code', { 
                    required: 'Zip Code is required',
                    pattern: {
                      value: /^[0-9]+$/,
                      message: 'Zip Code must be a number'
                    }
                  })}
                />
                {errors.zip_code && <span className="text-danger">{errors.zip_code.message}</span>}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="contactNo">Contact No</label>
                <input
                  type="text"
                  name="contact_no"
                  {...register('contact_no', { 
                    required: 'Contact No is required',
                    // pattern: {
                    //   value: /^[0-9]+$/,
                    //   message: 'Contact No must be a number'
                    // }
                  })}
                />
                {errors.contact_no && <span className="text-danger">{errors.contact_no.message}</span>}
              </div>
              <button className='btn btn-sm btn-primary w-25' style={{marginLeft: "8px"}} type="submit">{`${address ? "Save Changes": "Add Address"} `}</button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
