import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, clearCart } from '../redux/slices/cartSlice';
import CartItems from '../ecommerce/CartItems';
import { Link } from 'react-router-dom';

export default function Cart() {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);

  const calculateSubtotal = () => cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const shippingCost = subtotal * 0.05; // 5% flat rate shipping
    return (subtotal + shippingCost).toFixed(2);
  };

  const handleRemoveFromCart = (item) => dispatch(removeFromCart(item));
  const handleClearCart = () => dispatch(clearCart());

  return (
    <div>
      <div className="page-header breadcrumb-wrap">
        {/* Header content */}
      </div>

      {!cartItems.length ? (
        <div className="text-center py-5">
          <h1 className="heading-2 mb-10 text-center">Empty Cart</h1>
          <br />
          <Link to="/shop" className="btn btn-success">Shop Now</Link>
        </div>
      ) : (
        <div className="container mb-80 mt-50">
          <div className="row">
            <div className="col-lg-8 mb-40">
              <h1 className="heading-2 mb-10">Your Cart</h1>
              <div className="d-flex justify-content-between">
                <h6 className="text-body">
                  There are <span className="text-brand">{cartItems.length}</span> products in your cart
                </h6>
                <h6 className="text-body">
                  <a className="text-muted" onClick={handleClearCart}>
                    <i className="fi-rs-trash mr-5" />Clear Cart
                  </a>
                </h6>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <div className="table-responsive shopping-summery">
                <table className="table table-wishlist">
                  <thead>
                    <tr className="main-heading">
                      <th className="custome-checkbox start pl-30"></th>
                      <th scope="col" colSpan={2}>Product</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Subtotal</th>
                      <th scope="col" className="end">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item) => (
                      <CartItems key={item.id} item={item} removeCartItem={handleRemoveFromCart} />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="divider-2 mb-30" />
              <div className="cart-action d-flex justify-content-between">
                <a className="btn">
                  <i className="fi-rs-arrow-left mr-10" />
                  Continue Shopping
                </a>
                <a className="btn mr-10 mb-sm-15">
                  <i className="fi-rs-refresh mr-10" />
                  Update Cart
                </a>
              </div>

              <div className="row mt-50">
                <div className="col-lg-7">
                  {/* Calculate shipping form */}
                </div>
                <div className="col-lg-5">
                  {/* Apply coupon form */}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="border p-md-4 cart-totals ml-30">
                <div className="table-responsive">
                  <table className="table no-border">
                    <tbody>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Subtotal</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">${calculateSubtotal()}</h4>
                        </td>
                      </tr>
                      <tr>
                        <td scope="col" colSpan={2}>
                          <div className="divider-2 mt-10 mb-10" />
                        </td>
                      </tr>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Tax/Vat</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h5 className="text-heading text-end">5%</h5>
                        </td>
                      </tr>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Total</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">${calculateTotal()}</h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Link
                  to={{
                    pathname: "/checkout",
                    state: { calculateSubtotal, calculateTotal }
                  }}
                  className="btn mb-20 w-100"
                >
                  Proceed To CheckOut
                  <i className="fi-rs-sign-out ml-15" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
