// FilterProduct.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProductsByCategory, selectFilteredProducts } from '../redux/slices/filterProductSlice';
import { ProductInfo } from '../ecommerce/product/ProductInfo';

export default function FilterProduct () {
  const { category } = useParams();
  const dispatch = useDispatch();
  const products = useSelector(selectFilteredProducts);
  const loading = useSelector((state) => state.filterProducts.loading);
  const error = useSelector((state) => state.filterProducts.error);
  const [showModel, setShowModel] = useState(false);

  const [sortOption, setSortOption] = useState('featured'); // Default sort option
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);

  // Sorting function
  const sortProducts = (products) => {
    switch (sortOption) {
      case 'lowToHigh':
        return products.sort((a, b) => a.price - b.price);
      case 'highToLow':
        return products.sort((a, b) => b.price - a.price);
      default:
        return products;
    }
  };

  // Filtering function
  const filterProducts = (products) => {
    return products.filter(product => product.price >= minPrice && product.price <= maxPrice);
  };

  // Apply sorting and filtering
  const sortedAndFilteredProducts = filterProducts(sortProducts([...products]));

  const inputStyle = {margin: "5px", padding: "5px", width: "100px", height: "30px", fontSize: "12px"};

  useEffect(() => {
    if (category) {
      dispatch(fetchProductsByCategory(category));
    }
  }, [category, dispatch]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <main className="main" style={{transform: 'none'}}>
        <div className="page-header mt-30 mb-50">
          <div className="container">
            <div className="archive-header">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <h1 className="mb-15">{category}</h1>
                  <div className="breadcrumb">
                    <a href="index.html" rel="nofollow"><i className="fi-rs-home mr-5" />Home</a>
                    <span /> Shop <span /> {category}
                  </div>
                </div>
                <div className="col-xl-9 text-end d-none d-xl-block">
                  <ul className="tags-list">
                    <li className="hover-up">
                      <a href="blog-category-grid.html"><i className="fi-rs-cross mr-10" />{category}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-30" style={{transform: 'none'}}>
          <div className="row flex-row-reverse" style={{transform: 'none'}}>
            <div className="col-lg-4-5">
              <div className="shop-product-fillter">
                <div className="totall-product">
                  <p>We found <strong className="text-brand">{products.length}</strong> items for you!</p>
                </div>
                <div className="sort-by-product-area">
                  <div className="sort-by-cover mr-10">
                    <div className="sort-by-product-wrap">
                      <div className="sort-by">
                        <span><i className="fi-rs-apps" />Show:</span>
                      </div>
                      <div className="sort-by-dropdown-wrap">
                        <span> 50 <i className="fi-rs-angle-small-down" /></span>
                      </div>
                    </div>
                    <div className="sort-by-dropdown">
                      <ul>
                        <li><a className="active" href="#">50</a></li>
                        <li><a href="#">100</a></li>
                        <li><a href="#">150</a></li>
                        <li><a href="#">200</a></li>
                        <li><a href="#">All</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="sort-by-cover">
                  <div className="sort-by-product-wrap">
                    <div className="sort-by">
                      <span><i className="fi-rs-apps-sort" />Sort by Price:</span>
                    </div>
                    <div className="sort-by-dropdown-wrap">
                      <select onChange={(e) => setSortOption(e.target.value)} className='text-success'>
                        <option value="featured">Featured</option>
                        <option value="lowToHigh">Low to High</option>
                        <option value="highToLow">High to Low</option>
                      </select>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div className="row product-grid">
                {/* <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                  <div className="product-cart-wrap mb-30">
                    <div className="product-img-action-wrap">
                      <div className="product-img product-img-zoom">
                        <a href="shop-product-right.html">
                          <img className="default-img" src="assets/imgs/shop/product-1-1.jpg"/>
                          <img className="hover-img" src="assets/imgs/shop/product-1-2.jpg"/>
                        </a>
                      </div>
                      <div className="product-action-1">
                        <a aria-label="Add To Wishlist" className="action-btn" href="shop-wishlist.html"><i className="fi-rs-heart" /></a>
                        <a aria-label="Compare" className="action-btn" href="shop-compare.html"><i className="fi-rs-shuffle" /></a>
                        <a aria-label="Quick view" className="action-btn" data-bs-toggle="modal" data-bs-target="#quickViewModal"><i className="fi-rs-eye" /></a>
                      </div>
                      <div className="product-badges product-badges-position product-badges-mrg">
                        <span className="hot">Hot</span>
                      </div>
                    </div>
                    <div className="product-content-wrap">
                      <div className="product-category">
                        <a href="shop-grid-right.html">Snack</a>
                      </div>
                      <h2><a href="shop-product-right.html">Seeds of Change Organic Quinoe</a></h2>
                      <div className="product-rate-cover">
                        <div className="product-rate d-inline-block">
                          <div className="product-rating" style={{width: '90%'}} />
                        </div>
                        <span className="font-small ml-5 text-muted"> (4.0)</span>
                      </div>
                      <div>
                        <span className="font-small text-muted">By <a href="vendor-details-1.html">NestFood</a></span>
                      </div>
                      <div className="product-card-bottom">
                        <div className="product-price">
                          <span>$28.85</span>
                          <span className="old-price">$32.8</span>
                        </div>
                        <div className="add-cart">
                          <a className="add" href="shop-cart.html"><i className="fi-rs-shopping-cart mr-5" />Add </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {!products || products.length === 0 && (
                    <div style={{minHeight: "400px"}}>
                      <h1 className='text-center mt-5'>No Product found</h1>
                    </div>
                )}

                {loading ? <p>Loading...</p> : error ? <p>Error: {error}</p> : sortedAndFilteredProducts.map(product => (
                  <ProductInfo key={product.id} product={product} showModel={showModel} setShowModel={setShowModel} />
                ))}


                  {/* products */}
                {/*end product card*/}
              </div>
              {/*product grid*/}
              <div className="pagination-area mt-20 mb-20">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-start">
                    <li className="page-item">
                      <a className="page-link" href="#"><i className="fi-rs-arrow-small-left" /></a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item active"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link dot" href="#">...</a></li>
                    <li className="page-item"><a className="page-link" href="#">6</a></li>
                    <li className="page-item">
                      <a className="page-link" href="#"><i className="fi-rs-arrow-small-right" /></a>
                    </li>
                  </ul>
                </nav>
              </div>
              <section className="section-padding pb-5">
                <div className="section-title">
                  <h3>Deals Of The Day</h3>
                  <a className="show-all" href="shop-grid-right.html">
                    All Deals
                    <i className="fi-rs-angle-right" />
                  </a>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="product-cart-wrap style-2">
                      <div className="product-img-action-wrap">
                        <div className="product-img">
                          <a href="shop-product-right.html">
                            <img src="assets/imgs/banner/banner-5.png"/>
                          </a>
                        </div>
                      </div>
                      <div className="product-content-wrap">
                        <div className="deals-countdown-wrap">
                          <div className="deals-countdown" data-countdown="2025/03/25 00:00:00"><span className="countdown-section"><span className="countdown-amount hover-up">263</span><span className="countdown-period"> days </span></span><span className="countdown-section"><span className="countdown-amount hover-up">10</span><span className="countdown-period"> hours </span></span><span className="countdown-section"><span className="countdown-amount hover-up">44</span><span className="countdown-period"> mins </span></span><span className="countdown-section"><span className="countdown-amount hover-up">40</span><span className="countdown-period"> sec </span></span></div>
                        </div>
                        <div className="deals-content">
                          <h2><a href="shop-product-right.html">Seeds of Change Organic Quinoa, Brown</a></h2>
                          <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                              <div className="product-rating" style={{width: '90%'}} />
                            </div>
                            <span className="font-small ml-5 text-muted"> (4.0)</span>
                          </div>
                          <div>
                            <span className="font-small text-muted">By <a href="vendor-details-1.html">NestFood</a></span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>$32.85</span>
                              <span className="old-price">$33.8</span>
                            </div>
                            <div className="add-cart">
                              <a className="add" href="shop-cart.html"><i className="fi-rs-shopping-cart mr-5" />Add </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="product-cart-wrap style-2">
                      <div className="product-img-action-wrap">
                        <div className="product-img">
                          <a href="shop-product-right.html">
                            <img src="assets/imgs/banner/banner-6.png"/>
                          </a>
                        </div>
                      </div>
                      <div className="product-content-wrap">
                        <div className="deals-countdown-wrap">
                          <div className="deals-countdown" data-countdown="2026/04/25 00:00:00"><span className="countdown-section"><span className="countdown-amount hover-up">659</span><span className="countdown-period"> days </span></span><span className="countdown-section"><span className="countdown-amount hover-up">10</span><span className="countdown-period"> hours </span></span><span className="countdown-section"><span className="countdown-amount hover-up">44</span><span className="countdown-period"> mins </span></span><span className="countdown-section"><span className="countdown-amount hover-up">40</span><span className="countdown-period"> sec </span></span></div>
                        </div>
                        <div className="deals-content">
                          <h2><a href="shop-product-right.html">Perdue Simply Smart Organics Gluten</a></h2>
                          <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                              <div className="product-rating" style={{width: '90%'}} />
                            </div>
                            <span className="font-small ml-5 text-muted"> (4.0)</span>
                          </div>
                          <div>
                            <span className="font-small text-muted">By <a href="vendor-details-1.html">Old El Paso</a></span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>$24.85</span>
                              <span className="old-price">$26.8</span>
                            </div>
                            <div className="add-cart">
                              <a className="add" href="shop-cart.html"><i className="fi-rs-shopping-cart mr-5" />Add </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 d-none d-lg-block">
                    <div className="product-cart-wrap style-2">
                      <div className="product-img-action-wrap">
                        <div className="product-img">
                          <a href="shop-product-right.html">
                            <img src="assets/imgs/banner/banner-7.png"/>
                          </a>
                        </div>
                      </div>
                      <div className="product-content-wrap">
                        <div className="deals-countdown-wrap">
                          <div className="deals-countdown" data-countdown="2027/03/25 00:00:00"><span className="countdown-section"><span className="countdown-amount hover-up">993</span><span className="countdown-period"> days </span></span><span className="countdown-section"><span className="countdown-amount hover-up">10</span><span className="countdown-period"> hours </span></span><span className="countdown-section"><span className="countdown-amount hover-up">44</span><span className="countdown-period"> mins </span></span><span className="countdown-section"><span className="countdown-amount hover-up">40</span><span className="countdown-period"> sec </span></span></div>
                        </div>
                        <div className="deals-content">
                          <h2><a href="shop-product-right.html">Signature Wood-Fired Mushroom</a></h2>
                          <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                              <div className="product-rating" style={{width: '80%'}} />
                            </div>
                            <span className="font-small ml-5 text-muted"> (3.0)</span>
                          </div>
                          <div>
                            <span className="font-small text-muted">By <a href="vendor-details-1.html">Progresso</a></span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>$12.85</span>
                              <span className="old-price">$13.8</span>
                            </div>
                            <div className="add-cart">
                              <a className="add" href="shop-cart.html"><i className="fi-rs-shopping-cart mr-5" />Add </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 d-none d-xl-block">
                    <div className="product-cart-wrap style-2">
                      <div className="product-img-action-wrap">
                        <div className="product-img">
                          <a href="shop-product-right.html">
                            <img src="assets/imgs/banner/banner-8.png"/>
                          </a>
                        </div>
                      </div>
                      <div className="product-content-wrap">
                        <div className="deals-countdown-wrap">
                          <div className="deals-countdown" data-countdown="2025/02/25 00:00:00"><span className="countdown-section"><span className="countdown-amount hover-up">235</span><span className="countdown-period"> days </span></span><span className="countdown-section"><span className="countdown-amount hover-up">10</span><span className="countdown-period"> hours </span></span><span className="countdown-section"><span className="countdown-amount hover-up">44</span><span className="countdown-period"> mins </span></span><span className="countdown-section"><span className="countdown-amount hover-up">40</span><span className="countdown-period"> sec </span></span></div>
                        </div>
                        <div className="deals-content">
                          <h2><a href="shop-product-right.html">Simply Lemonade with Raspberry Juice</a></h2>
                          <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                              <div className="product-rating" style={{width: '80%'}} />
                            </div>
                            <span className="font-small ml-5 text-muted"> (3.0)</span>
                          </div>
                          <div>
                            <span className="font-small text-muted">By <a href="vendor-details-1.html">Yoplait</a></span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>$15.85</span>
                              <span className="old-price">$16.8</span>
                            </div>
                            <div className="add-cart">
                              <a className="add" href="shop-cart.html"><i className="fi-rs-shopping-cart mr-5" />Add </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*End Deals*/}
            </div>
            <div className="col-lg-1-5 primary-sidebar sticky-sidebar" style={{position: 'relative', overflow: 'visible', boxSizing: 'border-box', minHeight: 1}}>
            <div className="sidebar-widget price_range range mb-30">
                <h5 className="section-title style-1 mb-30">Fill by price</h5>
                <div className="price-filter">
                  <div className="price-filter-inner">
                    <div id="slider-range-1" className="mb-20 noUi-target noUi-ltr noUi-horizontal noUi-background"><div className="noUi-base"><div className="noUi-origin noUi-connect" style={{left: '25%'}}><div className="noUi-handle noUi-handle-lower" /></div><div className="noUi-origin noUi-background" style={{left: '50%'}}><div className="noUi-handle noUi-handle-upper" /></div></div></div>
                    <div className="d-flex justify-content-between">
                      <div className="caption">From:  <br/>
                        <input 
                          type="number" 
                          style={inputStyle}
                          placeholder="Min Price" 
                          value={minPrice} 
                          onChange={(e) => setMinPrice(e.target.value)} 
                        />
                      </div>
                      <div className="caption">To: <br/> 
                        <input 
                          type="number" 
                          style={inputStyle}
                          placeholder="Max Price" 
                          value={maxPrice} 
                          onChange={(e) => setMaxPrice(e.target.value)} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="list-group">
                  <div className="list-group-item mb-10 mt-10">
                    <label className="fw-900">Color</label>
                    <div className="custome-checkbox">
                      <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox1" defaultValue />
                      <label className="form-check-label" htmlFor="exampleCheckbox1"><span>Red (56)</span></label>
                      <br />
                      <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox2" defaultValue />
                      <label className="form-check-label" htmlFor="exampleCheckbox2"><span>Green (78)</span></label>
                      <br />
                      <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox3" defaultValue />
                      <label className="form-check-label" htmlFor="exampleCheckbox3"><span>Blue (54)</span></label>
                    </div>
                    <label className="fw-900 mt-15">Item Condition</label>
                    <div className="custome-checkbox">
                      <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox11" defaultValue />
                      <label className="form-check-label" htmlFor="exampleCheckbox11"><span>New (1506)</span></label>
                      <br />
                      <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox21" defaultValue />
                      <label className="form-check-label" htmlFor="exampleCheckbox21"><span>Refurbished (27)</span></label>
                      <br />
                      <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox31" defaultValue />
                      <label className="form-check-label" htmlFor="exampleCheckbox31"><span>Used (45)</span></label>
                    </div>
                  </div>
                </div> */}
                {/* <a href="shop-grid-right.html" className="btn btn-sm btn-default"><i className="fi-rs-filter mr-5" /> Fillter</a> */}
              </div>
              {/* Fillter By Price */}
              
              {/* Product sidebar Widget */}
              <div className="theiaStickySidebar" style={{paddingTop: 0, paddingBottom: 1, position: 'static', transform: 'none', top: 0, left: '12.0125px'}}>
                <div className="sidebar-widget widget-category-2 mb-30">
                  <h5 className="section-title style-1 mb-30">Category</h5>
                  <ul>
                    <li>
                      <a href="shop-grid-right.html"> <img src="assets/imgs/theme/icons/category-1.svg"/>Milks &amp; Dairies</a><span className="count">30</span>
                    </li>
                  </ul>
                </div>
                {/* <div className="sidebar-widget price_range range mb-30">
                  <h5 className="section-title style-1 mb-30">Fill by price</h5>
                  <div className="price-filter">
                    <div className="price-filter-inner">
                      <div id="slider-range" className="mb-20 noUi-target noUi-ltr noUi-horizontal noUi-background"><div className="noUi-base"><div className="noUi-origin noUi-connect" style={{left: '25%'}}><div className="noUi-handle noUi-handle-lower" /></div><div className="noUi-origin noUi-background" style={{left: '50%'}}><div className="noUi-handle noUi-handle-upper" /></div></div></div>
                      <div className="d-flex justify-content-between">
                        <div className="caption">From: <strong id="slider-range-value1" className="text-brand">$500</strong></div>
                        <div className="caption">To: <strong id="slider-range-value2" className="text-brand">$1,000</strong></div>
                      </div>
                    </div>
                  </div>
                  <div className="list-group">
                    <div className="list-group-item mb-10 mt-10">
                      <label className="fw-900">Color</label>
                      <div className="custome-checkbox">
                        <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox1" defaultValue />
                        <label className="form-check-label" htmlFor="exampleCheckbox1"><span>Red (56)</span></label>
                        <br />
                        <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox2" defaultValue />
                        <label className="form-check-label" htmlFor="exampleCheckbox2"><span>Green (78)</span></label>
                        <br />
                        <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox3" defaultValue />
                        <label className="form-check-label" htmlFor="exampleCheckbox3"><span>Blue (54)</span></label>
                      </div>
                      <label className="fw-900 mt-15">Item Condition</label>
                      <div className="custome-checkbox">
                        <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox11" defaultValue />
                        <label className="form-check-label" htmlFor="exampleCheckbox11"><span>New (1506)</span></label>
                        <br />
                        <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox21" defaultValue />
                        <label className="form-check-label" htmlFor="exampleCheckbox21"><span>Refurbished (27)</span></label>
                        <br />
                        <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox31" defaultValue />
                        <label className="form-check-label" htmlFor="exampleCheckbox31"><span>Used (45)</span></label>
                      </div>
                    </div>
                  </div>
                  <a href="shop-grid-right.html" className="btn btn-sm btn-default"><i className="fi-rs-filter mr-5" /> Fillter</a>
                </div> */}
                <div className="sidebar-widget product-sidebar mb-30 p-30 bg-grey border-radius-10">
                  <h5 className="section-title style-1 mb-30">New products</h5>
                  <div className="single-post clearfix">
                    <div className="image">
                      <img src="assets/imgs/shop/thumbnail-3.jpg" alt="#" />
                    </div>
                    <div className="content pt-10">
                      <h5><a href="shop-product-detail.html">Chen Cardigan</a></h5>
                      <p className="price mb-0 mt-5">$99.50</p>
                      <div className="product-rate">
                        <div className="product-rating" style={{width: '90%'}} />
                      </div>
                    </div>
                  </div>
                  <div className="single-post clearfix">
                    <div className="image">
                      <img src="assets/imgs/shop/thumbnail-4.jpg" alt="#" />
                    </div>
                    <div className="content pt-10">
                      <h6><a href="shop-product-detail.html">Chen Sweater</a></h6>
                      <p className="price mb-0 mt-5">$89.50</p>
                      <div className="product-rate">
                        <div className="product-rating" style={{width: '80%'}} />
                      </div>
                    </div>
                  </div>
                  <div className="single-post clearfix">
                    <div className="image">
                      <img src="assets/imgs/shop/thumbnail-5.jpg" alt="#" />
                    </div>
                    <div className="content pt-10">
                      <h6><a href="shop-product-detail.html">Colorful Jacket</a></h6>
                      <p className="price mb-0 mt-5">$25</p>
                      <div className="product-rate">
                        <div className="product-rating" style={{width: '60%'}} />
                      </div>
                    </div>
                  </div>
                </div><div className="banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none animated" style={{visibility: 'visible'}}>
                  <img src="assets/imgs/banner/banner-11.png"/>
                  <div className="banner-text">
                    <span>Oganic</span>
                    <h4>
                      Save 17% <br />
                      on <span className="text-brand">Oganic</span><br />
                      Juice
                    </h4>
                  </div>
                </div></div></div>
          </div>
        </div>
      </main>
    </>
  )
}
