import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, clearCart } from '../redux/slices/cartSlice';
import { Link } from 'react-router-dom'
import CartItems from './../ecommerce/CartItems';
import { useNavigate, useLocation } from 'react-router-dom';
import Country from '../ecommerce/checkout/Country';
import Address, { addOpenModel } from '../ecommerce/checkout/Address';
import AddEditAddress from '../ecommerce/checkout/AddEditAddress';
import Payment from '../ecommerce/payment/Payment';
import NewUserOrder from '../ecommerce/checkout/NewUserOrder';
import ApplyCouponForm from '../ecommerce/checkout/ApplyCouponForm';
import { toast } from 'react-hot-toast';

export default function Checkout() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [promotion, setPromotion] = useState(null);

  const [taxVat, setTaxVat] = useState(2);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const cartItems = useSelector((state) => state.cart.items);
  const [selectedAddressId, setSelectedAddressId] = useState([]);
  const [address, setAddress] = useState(user ? user.addresses[0]: null);
  const [shipping, setShipping] = useState(0);
  const [states, setStates] = useState([]);
  

  const handleAddressChange = (id, addrData) => {
    setAddress(addrData);
    setSelectedAddressId(id);
  };

  console.log(cartItems, 'cartItems');
  

  const placeOrder = () => {
    const order = {
      user: user ? user : null,
      address: address,
      cartItems,
      subtotal: calculateSubtotal(),
      total: calculateTotal(),
      taxVat,
      shipping,
    };

    const existingOrders = JSON.parse(localStorage.getItem('userOrders')) || {};
    existingOrders.order = order;
    localStorage.setItem('userOrders', JSON.stringify(existingOrders));
    toast.success('Data Saved!', {
      duration: 6000,
    });
    toast.success('Make order payment!', {
      duration: 6000,
    });
    navigate('/checkout/payment');
  }

  // const { calculateSubtotal, calculateTotal } = location.state || {};

  useEffect(() => {
    if (!cartItems.length) {
      navigate('/cart');
    }
    if (user && user.user_profile && user.user_profile.def_address_id) {
      setSelectedAddressId(user.user_profile.def_address_id);
    }
  }, [user, cartItems, navigate]);

  const calculateSubtotal = () => cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const shippingCost = subtotal * (shipping/100); // 5% flat rate shipping
    const total = (subtotal + taxVat + shippingCost).toFixed(2)
    return total;
  };

  return (
    <>
      <main className="main">
        {/* {console.log(user)} */}
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="index.html" rel="nofollow"><i className="fi-rs-home mr-5" />Home</a>
              <span /> Shop
              <span /> Checkout
            </div>
          </div>
        </div>
        <div className="container mb-80 mt-50">
          <div className="row">
            <div className="col-lg-8 mb-40">
              <h1 className="heading-2 mb-10">Checkout</h1>
              <div className="d-flex justify-content-between">
                <h6 className="text-body">There are <span className="text-brand">{cartItems.length}</span> products in your cart</h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6 mb-sm-15 mb-lg-0 mb-md-3 mb-5">
                  {!user && <div className="toggle_info">
                    <span><i className="fi-rs-user mr-10" /><span className="text-muted font-lg">Already have an account?</span> <Link to="/login"><strong>Click here to login</strong></Link></span>
                  </div>}
                </div>
                
              </div>
              {!user? <div><NewUserOrder setShipping={setShipping} shipping={shipping} cartItems={cartItems} subtotal={calculateSubtotal()} total={calculateTotal()} taxVat={taxVat} shipping={shipping}  /></div> : <div className='row'>
                <div>
                  <h4 className="ml-5">Billing Details</h4>
                </div>
                {user.addresses.map((item, index) => <Address key={index} address = {item} def_address_id = {selectedAddressId} onAddressChange={handleAddressChange} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />)}
                <div className="form-check form-switch ml-25 mt-5">
                  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked disabled/>
                  <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Ship to the same address</label>
                </div>
                <div>
                  <h4>Or</h4>
                  <button className='btn btn-sm btn-primary' onClick={openModal}>Create new address</button>
                  <AddEditAddress modalIsOpen={modalIsOpen} closeModal={closeModal}  />
                </div>
              </div> }
            </div>
            <div className="col-lg-5">
              <div className="border p-40 cart-totals ml-30 mb-50">
                <div className="d-flex align-items-end justify-content-between mb-30">
                  <h4>{cartItems.length>1? "Your Orders": "Your Order"}</h4>
                  <h6 className="text-muted">Subtotal</h6>
                </div>
                <div className="divider-2 mb-30" />
                <div className="table-responsive order_table checkout">
                <div className='mb-3'>
                    { !promotion && <ApplyCouponForm setPromotion={setPromotion} />}
                  </div>
                  <table className="table no-border">
                    <tbody>

                      {cartItems.map((item, index) => (
                         <tr key={index}>
                         <td className="image product-thumbnail" style={{maxWidth: "80px"}}><img className="w-50" src={`http://127.0.0.1:8000${item.images[0].image_name}`} alt="#" /></td>
                         <td>
                           <h6 className="w-160 mb-5"><a href="shop-product-full.html" className="text-heading">{item.product_name}</a></h6>
                           <div className="product-rate-cover">
                             <div className="product-rate d-inline-block">
                               <div className="product-rating" style={{width: '90%'}}>
                               </div>
                             </div>
                             <span className="font-small ml-5 text-muted"> (4.0)</span>
                           </div>
                         </td>
                         <td>
                           <h6 className="text-muted pl-20 pr-20">x {item.quantity}</h6>
                         </td>
                         <td>
                           <h6 className="text-brand">${(item.quantity * item.price).toFixed(2)}</h6>
                         </td>
                       </tr>
                      ))}
                     
                    </tbody>
                  </table>
                  
                  <div style={{float: "right"}}>
                    <h6 className="text-brand mb-2">Subtotal : ${calculateSubtotal()}</h6>
                    <h6 className="text-brand mb-2">Shipping : ${shipping}</h6>
                    <h6 className="text-brand mb-2">Tax/Vat : 2%</h6>
                    {console.log(promotion)}
                    {promotion && <h6 className="text-warning mb-2">Promotional Discount : {promotion.discount}%</h6>}
                    {promotion && <h6 className="text-brand mb-2">Discount Amount: ${(calculateTotal() * (promotion.discount/100)).toFixed(2)}</h6>}
                    <h6 className="text-brand mb-2">{!promotion? `Total : ${calculateTotal()}` : <>Total :<del className='text-danger'> ${calculateTotal()}</del> ${(calculateTotal() - (calculateTotal() * (promotion.discount/100))).toFixed(2)}</>} </h6>
                  </div>
                </div>
                {user && <button type="submit" className='btn btn-lg' onClick={() => placeOrder()}>Place an Order<i className="fi-rs-sign-out ml-15" /></button>}
              </div>
              {/* <Payment /> */}
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
