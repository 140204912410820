import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendorShops } from '../redux/slices/vendorShopsSlice';
import { useNavigate } from 'react-router-dom';

export default function Vendors() {
  const dispatch = useDispatch();
  const { vendorShops, loading, error } = useSelector(state => state.vendorShops);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchVendorShops());
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const handleVendorShop = (shopname) => {
    const formattedShopname = shopname.replace(/\s+/g, '-');
    navigate(`/vendor-shop/${formattedShopname}`);
  };

  return (
    <>
      <div>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="index.html" rel="nofollow"><i className="fi-rs-home mr-5" />Home</a>
              <span /> Vendors List
            </div>
          </div>
        </div>
        <div className="page-content pt-50">
          <div className="container">
            <div className="archive-header-2 text-center">
              <h1 className="display-2 mb-50">Vendors List</h1>
              <div className="row">
                <div className="col-lg-5 mx-auto">
                  <div className="sidebar-widget-2 widget_search mb-50">
                    <div className="search-form">
                      <form action="#">
                        <input type="text" placeholder="Search vendors (by name or ID)..." />
                        <button type="submit"><i className="fi-rs-search" /></button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-50">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="shop-product-fillter">
                  <div className="totall-product">
                    <p>We have <strong className="text-brand">{vendorShops.length}</strong> vendors now</p>
                  </div>
                  <div className="sort-by-product-area">
                    <div className="sort-by-cover mr-10">
                      <div className="sort-by-product-wrap">
                        <div className="sort-by">
                          <span><i className="fi-rs-apps" />Show:</span>
                        </div>
                        <div className="sort-by-dropdown-wrap">
                          <span> 50 <i className="fi-rs-angle-small-down" /></span>
                        </div>
                      </div>
                      <div className="sort-by-dropdown">
                        <ul>
                          <li><a className="active" href="#">50</a></li>
                          <li><a href="#">100</a></li>
                          <li><a href="#">150</a></li>
                          <li><a href="#">200</a></li>
                          <li><a href="#">All</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="sort-by-cover">
                      <div className="sort-by-product-wrap">
                        <div className="sort-by">
                          <span><i className="fi-rs-apps-sort" />Sort by:</span>
                        </div>
                        <div className="sort-by-dropdown-wrap">
                          <span> Featured <i className="fi-rs-angle-small-down" /></span>
                        </div>
                      </div>
                      <div className="sort-by-dropdown">
                        <ul>
                          <li><a className="active" href="#">Mall</a></li>
                          <li><a href="#">Featured</a></li>
                          <li><a href="#">Preferred</a></li>
                          <li><a href="#">Total items</a></li>
                          <li><a href="#">Avg. Rating</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row vendor-grid">

            {vendorShops.map((shop, index) => (
              <div key={index} className="col-lg-3 col-md-6 col-12 col-sm-6">
                <div className="vendor-wrap mb-40">

                  <div className="vendor-img-action-wrap">
                    <div className="vendor-img">
                      <a>
                        <img className="default-img" src={`http://127.0.0.1:8000/${shop.logo_image.image_name}`} alt="vendor-img" />
                      </a>
                    </div>
                    <div className="product-badges product-badges-position product-badges-mrg">
                      <span className="hot">Mall</span>
                    </div>
                  </div>
                  <div className="vendor-content-wrap">
                    <div className="d-flex justify-content-between align-items-end mb-30">
                      <div>
                        <div className="product-category">
                          <span className="text-muted">Since 2012</span>
                        </div>
                        <h4 className="mb-5"><a href="vendor-details-1.html">{shop.shopname}</a></h4>
                        <div className="product-rate-cover">
                          <div className="product-rate d-inline-block">
                            <div className="product-rating" style={{width: shop.rating? shop.rating+"5" : "55"}} />
                          </div>
                          <span className="font-small ml-5 text-muted"> ({shop.rating? shop.rating: "4.8"})</span>
                        </div>
                      </div>
                      <div className="mb-10">
                        <span className="font-small total-product">{shop.products_count} products</span>
                      </div>
                    </div>
                    <div className="vendor-info mb-30">
                      <ul className="contact-infor text-muted">
                        <li><img src="assets/imgs/theme/icons/icon-location.svg" alt="vendor-img" /><strong>Address: </strong> <span>{shop.location}</span></li>
                        <li><img src="assets/imgs/theme/icons/icon-contact.svg" alt="vendor-img" /><strong>Call Us:</strong><span>{shop.contact? shop.contact: "(+91) - 540-025-124553"}</span></li>
                      </ul>
                    </div>
                    <a onClick={() => handleVendorShop(shop.shopname)} className="btn btn-xs">Visit Store <i className="fi-rs-arrow-small-right" /></a>
                  </div>
                </div>
              </div>
            ))}
              

            </div>
          </div>
        </div>
      </div>

    </>
  )
}
