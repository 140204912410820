import React, { useState } from 'react';

const ShopImages = ({ images, setImages, nextStep, prevStep }) => {
    const [errors, setErrors] = useState({});

    const handleImageChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {
            validateImage(name, file);
            setImages({ ...images, [name]: file });
        }
    };

    const validateImage = (name, file) => {
        let error = '';

        // Validate file type (e.g., JPEG, PNG)
        const validTypes = ['image/jpeg', 'image/png'];
        if (!validTypes.includes(file.type)) {
            error = 'Only JPEG and PNG files are allowed';
        }

        // Validate file size (e.g., 2MB max)
        const maxSize = 2 * 1024 * 1024; // 2MB
        if (file.size > maxSize) {
            error = 'File size should be less than 2MB';
        }

        setErrors({ ...errors, [name]: error });
    };

    const isValid = () => {
        return !Object.values(errors).some(error => error !== '') &&
               images.logo_image && images.banner_image;
    };

    return (
      <div className='row justify-content-center m-5'>
          <div className='col-md-6 card shadow p-4 mb-5 bg-body rounded'>
            <h2>Shop Images</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Logo Image</label>
                        <input
                            type="file"
                            name="logo_image"
                            className={`form-control ${errors.logo_image ? 'is-invalid' : ''}`}
                            onChange={handleImageChange}
                        />
                        {errors.logo_image && <div className="invalid-feedback">{errors.logo_image}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Banner Image</label>
                        <input
                            type="file"
                            name="banner_image"
                            className={`form-control ${errors.banner_image ? 'is-invalid' : ''}`}
                            onChange={handleImageChange}
                        />
                        {errors.banner_image && <div className="invalid-feedback">{errors.banner_image}</div>}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Back
                </button>
                <button 
                    className="btn btn-primary" 
                    onClick={nextStep} 
                    disabled={!isValid()}>
                    Next
                </button>
            </div>
          </div>
        </div>
    );
};

export default ShopImages;
