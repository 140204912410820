import React, { useState } from 'react';
import { toast } from 'react-hot-toast'; 
import api from '../api/api';
import OrderTimeline from '../ecommerce/dashboard/OrderTimeline';

export default function TrackYourProduct() {
    const [trackingNumber, setTrackingNumber] = useState('');
    const [orderStatus, setOrderStatus] = useState(null);
    const [error, setError] = useState('');
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      try {
        const response = await api.get(`/order/status/${trackingNumber}`);
        setOrderStatus(response.data.status);
        toast.success(response.data.status);
        setError('');
      } catch (err) {
        setOrderStatus(null);
        toast.error(err.response && err.response.data.error)
        setError(err.response ? err.response.data.error : 'An error occurred');
      }
    };

  return (
    <>
      <div className='row justify-content-center'>
        <div className="col-md-8 card" style={{margin: "60px 0"}}>
          <div className="card-header">
            <h3 className="mb-0">Orders tracking</h3>
          </div>
          <div className="card-body contact-from-area">
            <p>To track your order please enter your OrderID in the box below and press "Track" button. This was given to you on your receipt and in the confirmation email you should have received.</p>
            <div className="row">
              <div className="col-lg-6">
                <form className="contact-form-style mt-30 mb-50" onSubmit={handleSubmit}>
                  <div className="input-style mb-20">
                    <label>Order Tracking ID</label> <br/>
                    {orderStatus && <p className='text-success'>Order Status: {orderStatus}</p>}
                    {error && <p className="text-danger">{error}</p>}
                    <input
                      name="order-id"
                      placeholder="Found in your order confirmation email"
                      type="text"
                      value={trackingNumber}
                      onChange={(e) => setTrackingNumber(e.target.value)}
                      required
                    />
                  </div>
                  
                  <button className="submit submit-auto-width" type="submit">Track</button>
                </form>
              </div>
              <div className='col-md-6'>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                  {orderStatus && <OrderTimeline status={orderStatus} />}
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
