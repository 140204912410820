import React from 'react';

export default function Addresses({ addresses }) {
  return (
    <div className="tab-pane fade" id="address" role="tabpanel" aria-labelledby="address-tab">
      <div className="row">
        {addresses.map((item, index) => (
          <div className="col-lg-6 shadow-sm p-3 mb-5 bg-body rounded" key={index}>
            <div className="card mb-3 mb-lg-0">
              <div className="card-header pb-0">
                <h4 className="mb-0">Billing Address</h4>
              </div>
              <div className="card-body">
                <address>
                  Address: {item.address_line_1}<br />
                  State: {item.state}<br />
                  Citry: {item.city}<br />
                  Zip Code: {item.zip_code}<br />
                  <p className='pt-5'>Contact: {item.contact_no} <br /></p>
                </address>
                <a href="#" className="btn-small">Edit</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
