import React, { useState } from 'react';
import UserInfo from '../ecommerce/vendorRegistration/UserInfo';
import ShopInfo from '../ecommerce/vendorRegistration/ShopInfo';
import ShopImages from '../ecommerce/vendorRegistration/ShopImages';
import ShopPolicies from '../ecommerce/vendorRegistration/ShopPolicies';
import ReviewSubmit from '../ecommerce/vendorRegistration/ReviewSubmit';
import api from '../api/api';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const VendorRegistration = () => {
    const [step, setStep] = useState(1);
    const [user, setUser] = useState({});
    const [shop, setShop] = useState({});
    const [images, setImages] = useState({});
    const [policies, setPolicies] = useState({});
    const navigate = useNavigate();

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

  
const handleSubmit = async () => {
  const formData = new FormData();

  // Add user data
  formData.append('user[fname]', user.fname);
  formData.append('user[lname]', user.lname);
  formData.append('user[email]', user.email);
  formData.append('user[password]', user.password);
  formData.append('user[phone_no]', user.phone_no);
  formData.append('user[website]', user.website);
  formData.append('user[gender]', user.gender);
  formData.append('user[date_of_birth]', user.date_of_birth);

  // Add shop data
  formData.append('shop[shopname]', shop.shopname);
  formData.append('shop[shop_email]', shop.shop_email);
  formData.append('shop[contact]', shop.contact);
  formData.append('shop[location]', shop.location);

  // Add images if available
  if (images.logo_image) {
      formData.append('images[logo_image]', images.logo_image);
  }
  if (images.banner_image) {
      formData.append('images[banner_image]', images.banner_image);
  }

  // Add policies
  formData.append('policies[sell_commission_percentage]', policies.sell_commission_percentage);
  formData.append('policies[product_tax_percent]', policies.product_tax_percent);
  formData.append('policies[product_vat_percent]', policies.product_vat_percent);

  try {
      const response = await api.post('/vendor/register', formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      });

      if (response.status === 200) {
        toast.success('Vendor registered successfully!', {
          duration: 6000,
        });
        navigate('/');
          
          // alert('Vendor registered successfully');
          // Handle success (e.g., redirect to login)
      }
  } catch (error) {
      if (error.response && error.response.status === 422) {
          // Handle validation errors
          console.error(error.response.data.errors);
          toast.error('Validation error. Please check the form for errors.', {
            duration: 6000,
          })
          // alert('Please check the form for errors.');
      } else {
          console.error(error);
          toast.error('An error occurred. Please try again.', {
            duration: 6000,
          })
          // alert('An error occurred. Please try again.');
      }
  }
};

    switch (step) {
        case 1:
            return <UserInfo user={user} setUser={setUser} nextStep={nextStep} />;
        case 2:
            return <ShopInfo shop={shop} setShop={setShop} nextStep={nextStep} prevStep={prevStep} />;
        case 3:
            return <ShopImages images={images} setImages={setImages} nextStep={nextStep} prevStep={prevStep} />;
        case 4:
            return <ShopPolicies policies={policies} setPolicies={setPolicies} nextStep={nextStep} prevStep={prevStep} />;
        case 5:
            return <ReviewSubmit user={user} shop={shop} images={images} policies={policies} handleSubmit={handleSubmit} prevStep={prevStep} />;
        default:
            return null;
    }
};

export default VendorRegistration;

