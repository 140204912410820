
import React, { useEffect } from 'react';
import { Main } from './components/Main';
import ChatBot from './components/ecommerce/chat/ChatBot';

function App() {
  useEffect(() => {
    const scriptUrls = [
      "/assets/js/vendor/modernizr-3.6.0.min.js",
      "/assets/js/vendor/jquery-3.6.0.min.js",
      "/assets/js/vendor/jquery-migrate-3.3.0.min.js",
      "/assets/js/vendor/bootstrap.bundle.min.js",
      "/assets/js/plugins/slick.js",
      "/assets/js/plugins/jquery.syotimer.min.js",
      "/assets/js/plugins/waypoints.js",
      "/assets/js/plugins/wow.js",
      "/assets/js/plugins/perfect-scrollbar.js",
      "/assets/js/plugins/magnific-popup.js",
      "/assets/js/plugins/select2.min.js",
      "/assets/js/plugins/counterup.js",
      "/assets/js/plugins/jquery.countdown.min.js",
      "/assets/js/plugins/images-loaded.js",
      "/assets/js/plugins/isotope.js",
      "/assets/js/plugins/scrollup.js",
      "/assets/js/plugins/jquery.vticker-min.js",
      "/assets/js/plugins/jquery.theia.sticky.js",
      "/assets/js/plugins/jquery.elevatezoom.js",
      "/assets/js/main.js",
      // // "/assets/js/shop.js?v=5.3",
    ];

    const loadScript = (url) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = () => resolve(url);
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
        document.body.appendChild(script);
      });
    };

    const loadScriptsSequentially = async (urls) => {
      for (const url of urls) {
        try {
          await loadScript(url);
          // console.log(`Loaded script: ${url}`);
        } catch (error) {
          console.error(error);
          break;
        }
      }
    };

    loadScriptsSequentially(scriptUrls);

    return () => {
      scriptUrls.forEach((url) => {
        const script = Array.from(document.scripts).find(s => s.src.includes(url));
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  return (
    <>
      <Main />
    </>
  );
}

export default App;
