// import axios from 'axios';

// const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
// const api = axios.create({
//   baseURL: 'http://127.0.0.1:8000/api', // Replace with your actual API URL
//   headers: {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json',
//     'X-CSRF-TOKEN': csrfToken,
//   },
// });

// // Add a request interceptor to add the Authorization header
// api.interceptors.request.use(config => {
//   const token = localStorage.getItem('token'); // Replace with your actual token storage
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

// export default api;


// // // api.js
// // import axios from 'axios';

// // const api = axios.create({
// //     baseURL: 'http://127.0.0.1:8000/api',
// //     // withCredentials: true, // Required for CSRF
// // });

// // export default api;

// // src/api/api.js

import axios from 'axios';

// Set base URL for your API
const api = axios.create({
  baseURL: 'http://niduscart.niduslab.com/api', // Replace with your actual API URL
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true, // This is required to send cookies with requests (CSRF)
});

// Add a request interceptor to include CSRF token and Authorization header
api.interceptors.request.use(config => {
  // Get CSRF token from meta tag (typically set in your Laravel blade templates)
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
  if (csrfToken) {
    config.headers['X-CSRF-TOKEN'] = csrfToken;
  }

  // Get Authorization token from localStorage or wherever you store it
  const token = localStorage.getItem('token'); // Replace with your actual token storage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, error => {
  // Handle error
  return Promise.reject(error);
});

// You can also add a response interceptor if you need to handle specific responses globally
api.interceptors.response.use(
  response => response,
  error => {
    // Handle errors globally, for example:
    if (error.response.status === 401) {
      // Redirect to login page or refresh token
      console.log('Unauthorized, redirecting to login...');
    }
    return Promise.reject(error);
  }
);

export default api;



