import React, { useState } from 'react';

export default function Orders({ userOrders, cusLayout, setCusLayout }) {
  const [orderProducts, setOrderProducts] = useState({});
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(null);

  const productsView = (products, index) => {
    setCusLayout(true);
    setOrderProducts(products);
    setSelectedOrderIndex(index);
  };

  const formatedDate = (serverDate) => {
    const date = new Date(serverDate);
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric', 
      hour12: true, 
      timeZoneName: 'short' 
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  return (
    <div className="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
      <div className="card">
        <div className="card-header">
          <h3 className="mb-0">Your Orders</h3>
        </div>
        {userOrders.map((order, index) => {
          const status = order.status.charAt(0).toUpperCase() + order.status.slice(1);
          const warningColor = (status === "Cancelled") ? "text-danger" :
            (status === "Processing") ? "text-warning" :
            (status === "Pending") ? "text-info" :
            (status === "Shipped" || status === "Delivered") ? "text-success" :
            "text-dark";

          return (
            <div key={index}>
              <div className={`order-card d-flex shadow p-3 mb-5 mt-3 bg-body rounded`}>
                <div className="order-details ml-5 pl-5">
                  <div className="order-header">Order #{order.tracking_number}  
                    <span className={`border p-1 rounded ${warningColor} ml-5`}> 
                      <strong>{status}</strong> 
                    </span>
                  </div>
                  <div>Ordered Item: {order.items.length}</div>
                  <div className="order-date">Total Price: {order.total_amount} BTD</div>
                  <div className="order-date">Placed on {formatedDate(order.created_at)}</div>
                </div>
                <div className="ml-auto d-flex flex-column">
                  {status !== "Cancelled" && (
                    <button className="return-button bg-light">Return Package</button>
                  )}
                  <button className='return-button' onClick={() => productsView(order.items, index)}>View Products</button>
                </div>
              </div>
              {cusLayout && selectedOrderIndex === index && (
                <div className={`shadow p-3 mb-5 bg-body rounded`}>
                  <div className="card">
                    <div className='w-100'>
                      <button className='btn-sm btn btn-danger w-25' onClick={() => setCusLayout(false)} style={{float: "right"}}>Close</button>
                    </div>
                    {orderProducts && Object.entries(orderProducts).map(([key, value], index, array) => (
                      <div key={key} style={{ borderBottom: index !== array.length - 1 ? "1px solid #cbcbcb" : "none" }}>
                        <div className="order-card d-flex">
                          <div className="order-image">
                            <img src="https://via.placeholder.com/100" alt="Product Image" className="img-fluid" />
                          </div>
                          <div className="order-details ml-5">
                            <div>{value.product.product_name}</div>
                            <div>Qty: {value.quantity}</div>
                            <div className="order-header">SKU: {value.product.sku}</div>
                            <div className="order-date">Placed on {formatedDate(value.product.created_at)}</div>
                          </div>
                          <div className="ml-auto d-flex flex-column justify-content-end pb-5">
                            <div>
                              <div className="order-header text-left">Sub Product Price: ({value.product.price} x {value.quantity}) BDT</div>
                              <div className="order-header text-left">Total Price: {value.product.price * value.quantity} BDT</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
