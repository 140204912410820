import React from 'react';

const ReviewSubmit = ({ user, shop, images, policies, handleSubmit, prevStep }) => {


    return (
      <div className='row justify-content-center m-5'>
          <div className='col-md-6 card shadow p-4 mb-5 bg-body rounded'>
            <h2>Review & Submit</h2>
            <div className="row">
                <div className="col-md-6">
                    <h4>Personal Information</h4>
                    <p><strong>First Name:</strong> {user.fname}</p>
                    <p><strong>Last Name:</strong> {user.lname}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                    <p><strong>User Contact:</strong> {user.phone_no}</p>
                    <p><strong>Website:</strong> {user.website}</p>
                    <p><strong>Gender:</strong> {user.gender}</p>
                    <p><strong>DOB:</strong> {user.date_of_birth}</p>
                </div>
                <div className="col-md-6">
                    <h4>Shop Information</h4>
                    <p><strong>Shop Name:</strong> {shop.shopname}</p>
                    <p><strong>Shop Email:</strong> {shop.shop_email}</p>
                    <p><strong>Contact:</strong> {shop.contact}</p>
                    <p><strong>Location:</strong> {shop.location}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Shop Images</h4>
                    <p><strong>Logo Image:</strong> {images.logo_image?.name}</p>
                    <p><strong>Banner Image:</strong> {images.banner_image?.name}</p>
                </div>
                <div className="col-md-6">
                    <h4>Shop Policies</h4>
                    {/* <p><strong>Sell Commission Percentage:</strong> {policies.sell_commission_percentage}%</p> */}
                    <p><strong>Product Tax Percentage:</strong> {policies.product_tax_percent}%</p>
                    <p><strong>Product VAT Percentage:</strong> {policies.product_vat_percent}%</p>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Back
                </button>
                <button 
                    className="btn btn-success" 
                    onClick={handleSubmit}>
                    Submit
                </button>
            </div>
          </div>
        </div>
    );
};

export default ReviewSubmit;
