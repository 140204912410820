import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../redux/slices/productSlice';
import { ProductInfo } from './ProductInfo';

export default function ReletedProducts({ productShowcaseName }) {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector(state => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  // Filter products by category name
  const filteredProducts = products
    .filter(product => product.category.category_name === productShowcaseName);

  // Sort filtered products by created_at in descending order
  const sortedFilteredProducts = filteredProducts
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // Select the latest 4 products from the filtered results
  const latestFilteredProducts = sortedFilteredProducts.slice(0, 4);

  // If no products in the selected category, use all products and sort them
  const sortedAllProducts = products
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // Select the latest 4 products from all products
  const latestAllProducts = sortedAllProducts.slice(0, 4);

  // Determine which products to show based on availability
  const productsToShow = latestFilteredProducts.length > 0 ? latestFilteredProducts : latestAllProducts;

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      
      {productsToShow.length > 0 ? (
        <section className="product-tabs section-padding position-relative">
          <div className="container">
            <div className="section-title style-2 wow animate__animated animate__fadeIn">
              {/* <h3>{productShowcaseName}</h3> */}
              <h4 className="">{productShowcaseName ? 'Related Products' : 'Latest Products'}</h4>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                <div className="row product-grid-4">
                  {productsToShow.map((product) => (
                    <ProductInfo key={product.id} product={product} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <p>No products available.</p>
      )}
    </div>
  );
  
}
