import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import api from '../../api/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../redux/slices/cartSlice';

const stripePromise = loadStripe('pk_test_51HdcWhB3qy4Tas4Zew9BPaP0w81Vi5naE6IWz6udhq9cPm5ng2Sa2HMqXFWTlpHxW4XSIQbZxWuA7B3DpuRIhmFg00Ul3LV9jB');

function CheckoutForm() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [order, setOrder] = useState(null);
  const dispatch = useDispatch();
  const [response, setResponse] = useState(null);
  

  useEffect(() => {
    const userOrders = JSON.parse(localStorage.getItem('userOrders'));
    if (userOrders && userOrders.order) {
      setOrder(userOrders.order);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!order) {
      setError('Order not found');
      return;
    }
    setPaymentProcessing(true);
  
    const loadingToast = toast.loading('Processing payment...');
  
    try {
      const response = await api.post('/create-payment-intent', { amount: order.total * 100 });
      const clientSecret = response.data.clientSecret;
  
      const cardElement = elements.getElement(CardElement);
  
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${order.user.fname} ${order.user.lname}`,
          },
        },
      });
  
      if (error) {
        setError(error.message);
        toast.error('Payment failed: ' + error.message, { id: loadingToast });
        setPaymentProcessing(false);
      } else if (paymentIntent.status === 'succeeded') {
        const transactionDetails = {
          transaction_id: paymentIntent.id,
          payment_method: 'Card',
          amount: (paymentIntent.amount/100),
          currency: paymentIntent.currency
        };
  
        const updatedOrder = { ...order, transactionDetails };
  
        // Save order data in localStorage
        localStorage.setItem('userOrders', JSON.stringify({ order: updatedOrder }));
        // Save order data to database
        const res = await api.post('/create-order', { order: updatedOrder });
        setResponse(JSON.stringify(res.data));
        console.log('Response:', response);

        setPaymentSuccess(true);
        toast.success('Payment successful!', { id: loadingToast }, { duration: 6000 });
        toast.success('Order placed successfully!', { id: loadingToast }, { duration: 6000 });
        setPaymentProcessing(false);
        dispatch(clearCart());
        navigate('/order/invoice');
      }
    } catch (error) {
      setError(error.message);
      toast.error('Payment error: ' + error.message, { id: loadingToast });
      setPaymentProcessing(false);
    }
  };
  
  

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <div className='my-3'>
        <button className='btn btn-sm btn-secondary' type="submit" disabled={!stripe || paymentProcessing}>
          {paymentProcessing ? 'Processing...' : 'Pay Now'}
        </button>
        <span className='text-danger pt-2'>{error && <div>{error}</div>}</span>
        <span className='text-success'>{paymentSuccess && <div>Payment Successful!</div>}</span>
      </div>
    </form>
  );
}

export default function Payment() {
  return (
    <>
      <div className='row justify-content-center' style={{margin: "100px 0"}}>
        <div className='col-md-4 shadow p-3 mb-5 bg-body-tertiary rounded'>
          <div className="payment ml-30" style={{padding: "50px"}}>
            <h4 className="mb-30">Payment</h4>
            <div className="payment_option">
              <div className="custome-radio">
                <input className="form-check-input" required type="radio" name="payment_option" id="stripe" />
                <label className="form-check-label" htmlFor="stripe">Card Payment</label>
              </div>
            </div>
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
            <div className="payment-logo d-flex">
              <img className="mr-15" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/payment-paypal.svg`} alt="PayPal"/>
              <img className="mr-15" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/payment-visa.svg`} alt="Visa"/>
              <img className="mr-15" src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/payment-master.svg`} alt="MasterCard"/>
              <img src={`${process.env.PUBLIC_URL}/assets/imgs/theme/icons/payment-zapper.svg`} alt="Zapper"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
