import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

// Thunk for user login
export const loginUser = createAsyncThunk('auth/loginUser', async (credentials) => {
  const response = await api.post('/login', credentials);
  return response.data.data;
});

// Thunk for user logout
export const logoutUser = createAsyncThunk('auth/logoutUser', async () => {
  await api.post('/logout');
  return;
});

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('auth_token') || null, // Initialize token from localStorage if present
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem('user', JSON.stringify(action.payload.user)); // Store user in localStorage
      localStorage.setItem('auth_token', action.payload.token); // Store token in localStorage
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('user'); // Remove user from localStorage on logout
      localStorage.removeItem('auth_token'); // Remove token from localStorage on logout
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        localStorage.setItem('user', JSON.stringify(action.payload.user)); // Store user in localStorage
        localStorage.setItem('auth_token', action.payload.token); // Store token in localStorage
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        localStorage.removeItem('user'); // Remove user from localStorage on logout
        localStorage.removeItem('auth_token'); // Remove token from localStorage on logout
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;
