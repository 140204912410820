// shopProductsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

// Define initial state
const initialState = {
  shopProducts: [],
  loading: false,
  error: null,
};

// Define async thunk for fetching products for a specific shop
export const fetchShopProducts = createAsyncThunk(
  'shopProducts/fetchShopProducts',
  async (shopName) => {
    try {
      const formattedShopname = shopName?.replace(/-/g, ' ') || '';
      const response = await api.get(`/vendor-shop/${formattedShopname}`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create slice using createSlice
const shopProductsSlice = createSlice({
  name: 'shopProducts',
  initialState,
  reducers: {
    // Add reducers if needed
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShopProducts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShopProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.shopProducts = action.payload;
      })
      .addCase(fetchShopProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { } = shopProductsSlice.actions; // Add actions if needed

export const selectShopProducts = (state) => state.shopProducts.shopProducts;

export default shopProductsSlice.reducer;
