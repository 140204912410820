import React from 'react';

const OrderTimeline = ({ status }) => {
  // Define the steps and their status
  const steps = ['pending', 'processing', 'shipped', 'delivered', 'cancelled'];

  // Function to determine the status class based on the current step
  const getStepClass = (step) => {
    if (status === step) return 'current';
    if (steps.indexOf(step) < steps.indexOf(status)) return 'completed';
    return 'pending';
  };

  return (
    <div className="timeline-container">
      <h2>Order Status</h2>
      <div className="timeline">
        {steps.map((step, index) => (
          <div key={index} className={`timeline-step ${getStepClass(step)}`}>
            <div className="timeline-marker">
              <div className={`marker-circle ${getStepClass(step)}`}></div>
            </div>
            <div className="timeline-content">
              <h4 className="step-title">{step.charAt(0).toUpperCase() + step.slice(1)}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderTimeline;
