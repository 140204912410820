import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import { Footer } from "./layouts/Footer";
import Navbar from './layouts/nav/Navbar';
import Shop from './pages/Shop';
import Product from './pages/Product';
import Vendors from './pages/Vendors';
import Brands from './pages/Brands';
import About from './pages/About';
import TrackYourProduct from './pages/TrackYourProduct';
import { Home } from './pages/Home';
import Contact from './pages/Contact';
import VendorShop from './pages/VendorShop';
import Cart from './pages/Cart';
import WishList from './pages/WishList';
import Dashboard from './pages/Dashboard';
import Login from './layouts/auth/Login';
import Registration from './layouts/auth/Registration';
import PrivateRoute from './layouts/nav/PrivateRoute';
import FilterProduct from './pages/FilterProduct';
import PageNotFound from './pages/PageNotFound';
import Checkout from './pages/Checkout';
import Payment from './ecommerce/payment/Payment';
import { Toaster } from 'react-hot-toast'; // Updated imports
import Invoice from './pages/Invoice';
import VendorRegistration from './pages/VendorRegistration';

export const Main = () => {
  return (
    <Router>
      <Navbar />
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/product/:product_name' element={<Product />} />
        <Route path='/products/:category' element={<FilterProduct />} />
        <Route path='/vendors' element={<Vendors />} />
        <Route path='/top-brands' element={<Brands />} />
        <Route path='/vendor-shop/:shopname' element={<VendorShop />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/track-your-product' element={<TrackYourProduct />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/wishlist' element={<WishList />} />
        <Route path='/login' element={<Login />} />
        <Route path='/checkout/payment' element={<Payment />} />
        <Route path='/order/invoice' element={<Invoice />} />
        <Route path='/registration' element={<Registration />} />
        <Route path='/vendor-registration' element={<VendorRegistration />} />

        <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  )
}