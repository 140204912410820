import React, { useState } from 'react';

const ShopInfo = ({ shop, setShop, nextStep, prevStep }) => {
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setShop({ ...shop, [name]: value });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'shopname':
                if (!value) error = 'Shop name is required';
                break;
            case 'shop_email':
                if (!value) error = 'Shop email is required';
                else if (!/\S+@\S+\.\S+/.test(value)) error = 'Email is invalid';
                break;
            case 'contact':
                if (!value) error = 'Contact number is required';
                break;
            case 'location':
                if (!value) error = 'Location is required';
                break;
            default:
                break;
        }
        setErrors({ ...errors, [name]: error });
    };

    const isValid = () => {
        return !Object.values(errors).some(error => error !== '') &&
               Object.keys(shop).length >= 4; // Ensure all fields are filled
    };

    return (
      <div className='row justify-content-center m-5'>
          <div className='col-md-6 card shadow p-4 mb-5 bg-body rounded'>
           <div>
            <h2>Shop Information</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Store Name</label>
                        <input
                            type="text"
                            name="shopname"
                            className={`form-control ${errors.shopname ? 'is-invalid' : ''}`}
                            value={shop.shopname || ''}
                            onChange={handleChange}
                        />
                        {errors.shopname && <div className="invalid-feedback">{errors.shopname}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Store Email</label>
                        <input
                            type="email"
                            name="shop_email"
                            className={`form-control ${errors.shop_email ? 'is-invalid' : ''}`}
                            value={shop.shop_email || ''}
                            onChange={handleChange}
                        />
                        {errors.shop_email && <div className="invalid-feedback">{errors.shop_email}</div>}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Store Contact Number</label>
                        <input
                            type="text"
                            name="contact"
                            className={`form-control ${errors.contact ? 'is-invalid' : ''}`}
                            value={shop.contact || ''}
                            onChange={handleChange}
                        />
                        {errors.contact && <div className="invalid-feedback">{errors.contact}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Store Location</label>
                        <input
                            type="text"
                            name="location"
                            className={`form-control ${errors.location ? 'is-invalid' : ''}`}
                            value={shop.location || ''}
                            onChange={handleChange}
                        />
                        {errors.location && <div className="invalid-feedback">{errors.location}</div>}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Back
                </button>
                <button 
                    className="btn btn-primary" 
                    onClick={nextStep} 
                    disabled={!isValid()}>
                    Next
                </button>
            </div>
        </div>
            </div>
       </div>
    );
};

export default ShopInfo;
