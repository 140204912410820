import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './components/redux/store';
import Chatbot from './components/ecommerce/chat/ChatBot';
import Chat from './components/ecommerce/chat/Chat';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      {/* <Chatbot /> */}
      {/* <Chat /> */}
    </Provider>
  </React.StrictMode>
);

