import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast'; 
import api from '../../api/api';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city'; // For fetching country, state, city data


export default function NewUserOrder({ cartItems, subtotal, total, taxVat, setShipping, shipping  }) {
  const { register, handleSubmit, formState: { errors }, setError } = useForm();
  const navigate = useNavigate();
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    // Fetch all countries on component mount
    const countriesList = Country.getAllCountries().map(country => ({
      label: country.name,
      value: country.isoCode,
    }));
    setCountries(countriesList);
    if (states || countries) {
      updateShippingCost();
    }
  }, []);

   // Function to update the shipping cost with a small difference
   const updateShippingCost = () => {
    const minChange = -2; // Min change in shipping
    const maxChange = 2;  // Max change in shipping
    const randomChange = Math.random() * (maxChange - minChange) + minChange;

    let newShipping = shipping + randomChange;

    // Ensure the new shipping cost is within the range of $10 - $30
    if (newShipping < 10) newShipping = 10;
    if (newShipping > 30) newShipping = 30;

    setShipping(newShipping.toFixed(2)); // Update state with new shipping cost
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    
    // Fetch states based on selected country
    const statesList = State.getStatesOfCountry(selectedOption?.value).map(state => ({
      label: state.name,
      value: state.isoCode,
    }));
    setStates(statesList);
    setCities([]);  // Reset cities when country changes
    setSelectedState(null); // Reset selected state
    setSelectedCity(null); // Reset selected city
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    
    // Fetch cities based on selected state and country
    const citiesList = City.getCitiesOfState(selectedCountry?.value, selectedOption?.value).map(city => ({
      label: city.name,
      value: city.name,
    }));
    setCities(citiesList);
    setSelectedCity(null); // Reset selected city
  };

  const checkEmail = async (email) => {
    setEmailCheckLoading(true);
    try {
      const response = await api.post('/check-email', { email });
      setEmailExists(response.data.exists);
    } catch (error) {
      toast.error('Error checking email availability.');
    } finally {
      setEmailCheckLoading(false);
    }
  };

  const onFormSubmit = (data) => {
    if (emailExists) {
      setError('email', { type: 'manual', message: 'Email is already used' });
      return;
    }

    const user = {
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      password: data.password,
    };

    const address = {
      address_line_1: data.address_line_1,
      address_line_2: data.address_line_2,
      city: selectedCity?.label || '',
      state: selectedState?.label || '',
      country: selectedCountry?.label || '',
      zip_code: data.zip_code,
      contact_no: data.contact_no,
    };

    const order = {
      user,
      address,
      cartItems,
      subtotal,
      total,
      taxVat,
      shipping,
      additional_info: data.additional_info
    };

    const existingOrders = JSON.parse(localStorage.getItem('userOrders')) || {};
    existingOrders.order = order;
    localStorage.setItem('userOrders', JSON.stringify(existingOrders));

    toast.success('Data Saved!', { duration: 6000 });
    toast.success('Make order payment!', { duration: 6000 });

    navigate('/checkout/payment');
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} method="post">
      {/* User Information Fields */}
      <div className="row">
        <div className="form-group col-lg-6">
          <input
            type="text"
            {...register('fname', { required: 'First name is required' })}
            placeholder="First name"
          />
          {errors.fname && <p className='text-danger'>{errors.fname.message}</p>}
        </div>
        <div className="form-group col-lg-6">
          <input
            type="text"
            {...register('lname', { required: 'Last name is required' })}
            placeholder="Last name"
          />
          {errors.lname && <p className='text-danger'>{errors.lname.message}</p>}
        </div>
      </div>

         {/* Address Section with Country, State, City Select */}
         <div className="row">
        <div className="form-group col-lg-6">
          <input
            type="text"
            {...register('address_line_1', { required: 'Billing address is required' })}
            placeholder="Address"
          />
          {errors.address_line_1 && <p className='text-danger'>{errors.address_line_1.message}</p>}
        </div>
        <div className="form-group col-lg-6">
          <input
            type="text"
            {...register('address_line_2')}
            placeholder="Address line 2"
          />
        </div>
      </div>

      {/* Address Section with Country, State, City Select */}
      <div className="row">
        <div className="form-group col-lg-6">
          <Select
            options={countries}
            onChange={handleCountryChange}
            placeholder="Select Country"
          />
        </div>
        <div className="form-group col-lg-6">
          <Select
            options={states}
            onChange={handleStateChange}
            placeholder="Select State"
            isDisabled={!selectedCountry}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-lg-6">
          <Select
            options={cities}
            onChange={(selectedOption) => setSelectedCity(selectedOption)}
            placeholder="Select City"
            isDisabled={!selectedState}
          />
          {errors.city && <p className='text-danger'>{errors.city.message}</p>}
        </div>
        <div className="form-group col-lg-6">
          <input
            type="text"
            {...register('zip_code', { 
              required: 'Zip Code is required',
              pattern: {
                value: /^[0-9]+$/,
                message: 'Zip Code must be a number'
              }
            })}
            placeholder="Zip Code"
          />
          {errors.zip_code && <p className='text-danger'>{errors.zip_code.message}</p>}
        </div>
      </div>

      {/* Contact Info */}
      <div className="row">
        <div className="form-group col-lg-6">
          <input
            type="text"
            {...register('contact_no', { 
              required: 'Contact No is required',
              pattern: {
                value: /^[0-9]+$/,
                message: 'Contact No must be a number'
              }
            })}
            placeholder="Contact No"
          />
          {errors.contact_no && <p className='text-danger'>{errors.contact_no.message}</p>}
        </div>
      </div>

      {/* Additional Information and Email Section */}
      <div className="form-group mb-10">
        <textarea
          rows={3}
          {...register('additional_info')}
          placeholder="Additional information"
        />
      </div>
      <div className="row">
        <div className="form-group col-lg-6">
          <input
            type="text"
            {...register('email', { 
              required: 'Email is required',
              onBlur: async (e) => await checkEmail(e.target.value)
            })}
            placeholder="Email address"
          />
          {errors.email && <p className='text-danger'>{errors.email.message}</p>}
          {emailCheckLoading && <p className='text-info'>Checking email...</p>}
          {emailExists && !errors.email && <p className='text-danger'>Email is already used.</p>}
        </div>
        <div className="form-group col-lg-6">
          <input
            type="password"
            {...register('password', { required: 'Password is required' })}
            placeholder="Password"
          />
          {errors.password && <p className='text-danger'>{errors.password.message}</p>}
        </div>
      </div>

      <button type="submit" className='btn btn-sm'>Place an Order<i className="fi-rs-sign-out ml-15" /></button>
    </form>
  );
}
