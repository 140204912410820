import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Orders from '../ecommerce/dashboard/Orders';
import OrderTracking from '../ecommerce/dashboard/OrderTracking';
import Addressess from '../ecommerce/dashboard/Addressess';
import AccountDetails from '../ecommerce/dashboard/AccountDetails';
import Logout from '../layouts/auth/Logout';

export default function Dashboard() {
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const [cusLayout, setCusLayout] = useState(false);

  let changeLayout = () => {
    setCusLayout(true);
  }
  return (
    <>
     <div>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="index.html" rel="nofollow"><i className="fi-rs-home mr-5" />Home</a>
              <span /> Pages <span /> My Account
            </div>
          </div>
        </div>
        <div className="page-content pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="row">
                  <div className="col-md-3">
                    <div className="dashboard-menu">
                      <ul className="nav flex-column" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" id="dashboard-tab" data-bs-toggle="tab" href="#dashboard" role="tab" aria-controls="dashboard" aria-selected="false"><i className="fi-rs-settings-sliders mr-10" onClick={changeLayout} />Dashboard</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="orders-tab" data-bs-toggle="tab" href="#orders" role="tab" aria-controls="orders" aria-selected="false"><i className="fi-rs-shopping-bag mr-10" onClick={changeLayout} />Orders</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="track-orders-tab" data-bs-toggle="tab" href="#track-orders" role="tab" aria-controls="track-orders" aria-selected="false"><i className="fi-rs-shopping-cart-check mr-10" onClick={changeLayout} />Track Your Order</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="address-tab" data-bs-toggle="tab" href="#address" role="tab" aria-controls="address" aria-selected="true"><i className="fi-rs-marker mr-10" onClick={changeLayout} />My Address</a>
                        </li>
                        
                        <li className="nav-item">
                          <a className="nav-link" id="account-detail-tab" data-bs-toggle="tab" href="#account-detail" role="tab" aria-controls="account-detail" aria-selected="true"><i className="fi-rs-user mr-10" onClick={changeLayout} />Account details</a>
                        </li>
                        <li className="nav-item">
                          {/* <a className="nav-link" href="page-login.html"><i className="fi-rs-sign-out mr-10" onClick={changeLayout} />Logout</a> */}
                          <Logout fromDashboard={ true } />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content account dashboard-content pl-50">
                      <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="mb-0">Hello {user.fname+' '+user.lname}!</h3>
                          </div>
                          <div className="card-body">
                          <p>
                              From your account dashboard. you can easily check &amp; view your <a href="#">recent orders</a>,<br />
                              manage your <a href="#address">shipping and billing addresses</a> and <a href="#account-detail">edit your password and account details.</a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <Orders userOrders={user.userOrders.orders} cusLayout={cusLayout} setCusLayout={setCusLayout} />
                      <OrderTracking />
                      <Addressess addresses={user.addresses} />
                      {console.log(user)}
                      <AccountDetails email={user.email} fname={user.fname} lname={user.lname} gender={user.user_profile.gender} contact={user.user_profile.phone_no} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
