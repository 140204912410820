import React from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/slices/cartSlice';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

export const ProductInfo = ({ product, showModel, setShowModel }) => {
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    dispatch(addToCart(product));
    toast.success('Product added to cart!', {
      duration: 6000,
    });
  };
  // console.log(product);
  
  let product_image = product.images && product.images[0] ? `http://127.0.0.1:8000${product.images[0].image_name}` : "";

  return (
    <>
    {/* {console.log(product)} */}
      <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
        <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
          <div className="product-img-action-wrap">
            <div className="product-img product-img-zoom">
              <Link to={`/product/${product.product_name}`} state={{ product }}>
                <img className="default-img" src={product_image} alt={product.product_name} />
                <img className="hover-img" src={product_image} alt={product.product_name} />
              </Link>
            </div>

            {/* <div className="product-action-1">
                  <a aria-label="Add To Wishlist" className="action-btn" href="shop-wishlist.html"><i className="fi-rs-heart" /></a>
                  <a aria-label="Compare" className="action-btn" href="shop-compare.html"><i className="fi-rs-shuffle" /></a>
                  <a aria-label="Quick view" className="action-btn" onClick={() => setShowModel(true)}><i className="fi-rs-eye" /></a>
                </div> */}

            {/* <div className="product-action-1">
              <a aria-label="Add To Wishlist" className="action-btn" href="shop-wishlist.html"><i className="fi-rs-heart"></i></a>
              <a aria-label="Compare" className="action-btn" href="shop-compare.html"><i className="fi-rs-shuffle"></i></a>
              <a aria-label="Quick view" onClick={() => setShowModel(true)}><i className="fi-rs-eye"></i></a>
            </div> */}
            <div className="product-badges product-badges-position product-badges-mrg">
              <span className="hot">Hot</span>
            </div>
          </div>
          <div className="product-content-wrap">
            <div className="product-category">
              <a href="shop-grid-right.html">{product.category.category_name}</a>
            </div>
            <h2><Link to={`/product/${product.product_name}`} state={{ product }}>{product.product_name}</Link></h2>
            <div className="product-rate-cover">
              <div className="product-rate d-inline-block">
                <div className="product-rating" style={{ width: "80%" }}></div>
              </div>
              <span className="font-small ml-5 text-muted"> (4.0)</span>
            </div>
            <div>
              <span className="font-small text-muted">By <a href="vendor-details-1.html">{product.brand.brand_name}</a></span>
            </div>
            <div className="product-card-bottom">
              <div className="product-price">
                <span>${product.price}</span>
                <span className="old-price">$32.8</span>
              </div>
              <div className="add-cart">
                <a className="add" onClick={handleAddToCart}><i className="fi-rs-shopping-cart mr-5"></i>Add </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
