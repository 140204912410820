import React, { useState } from 'react';
import Slider from 'react-slick';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import '../../assets/css/custom/ImageSlider.css';

const CustomNextArrow = ({ onClick }) => (
  <div className="custom-arrow next-arrow" onClick={onClick}>
    <i className="fi-rs-arrow-small-right"></i>
  </div>
);

const CustomPrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev-arrow rounded-circle" onClick={onClick}>
    <i className="fi-rs-arrow-small-left"></i>
  </div>
);

const ImageSlider = ({ images }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: nav2,
    ref: slider => setNav1(slider),
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: nav1,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    ref: slider => setNav2(slider),
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  return (
    <div className="detail-gallery">
      <span className="zoom-icon"><i className="fi-rs-search" /></span>
      {/* MAIN SLIDES */}
      <Slider {...settingsMain} className="product-image-slider">
        {images.map((image, index) => (
          <div key={index}>
            <figure className="border-radius-10">
              <Zoom>
                <img src={"http://127.0.0.1:8000" + image.image_name} alt="Product Image" />
              </Zoom>
            </figure>
          </div>
        ))}
      </Slider>
      {/* THUMBNAILS */}
      {images.length > 1 && (
        <Slider {...settingsThumbs} className="slider-nav-thumbnails">
          {images.map((thumbnail, index) => (
            <div key={index}>
              <img src={"http://127.0.0.1:8000" + thumbnail.image_name} alt="Product Thumbnail" />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ImageSlider;



// import React, { useState } from 'react';
// import Slider from 'react-slick';
// import Zoom from 'react-medium-image-zoom';
// import 'react-medium-image-zoom/dist/styles.css';
// // import '../../assets/css/custom/ImageSlider.css'

// const ImageSlider = ({ images}) => {
//   const [nav1, setNav1] = useState(null);
//   const [nav2, setNav2] = useState(null);

//   const settingsMain = {
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     arrows: false,
//     fade: true,
//     asNavFor: nav2,
//     ref: slider => setNav1(slider),
//   };

//   const settingsThumbs = {
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     asNavFor: nav1,
//     dots: false,
//     centerMode: true,
//     focusOnSelect: true,
//     ref: slider => setNav2(slider),
//   };

//   return (
//     <div className="detail-gallery">
//       <span className="zoom-icon"><i className="fi-rs-search" /></span>
//       {/* MAIN SLIDES */}
//       <Slider {...settingsMain} className="product-image-slider">
//         {images.map((image, index) => (
//           <div key={index}>
//             <figure className="border-radius-10">
//               <Zoom>
//                 <img src={"http://127.0.0.1:8000/"+image.image_name} alt="Product Image" />
//               </Zoom>
//             </figure>
//           </div>
//         ))}
//       </Slider>
//       {/* THUMBNAILS */}
//       {images.length > 1 && (
//         <Slider {...settingsThumbs} className="slider-nav-thumbnails">
//           {images.map((thumbnail, index) => (
//             <div key={index}>
//               <img src={"http://127.0.0.1:8000/"+thumbnail.image_name} alt="Product Thumbnail" />
//             </div>
//           ))}
//         </Slider>
//       )}
//     </div>
//   );
// };

// export default ImageSlider;
