import React from 'react';

export default function Review({ reviews }) {
  // Calculate total reviews and rating distribution
  const totalReviews = reviews.length;
  const ratingCounts = [0, 0, 0, 0, 0]; // Array to store counts for 1-star to 5-star reviews
  let totalRating = 0;

  reviews.forEach(review => {
    const rating = review.rating;
    if (rating >= 1 && rating <= 5) { // Ensures rating is within the expected range
      ratingCounts[rating - 1] += 1; // Increment the correct star count
      totalRating += rating; // Add to total rating sum
    }
  });

  // Calculate the average rating, default to 0 if no reviews
  const averageRating = totalReviews > 0 ? (totalRating / totalReviews).toFixed(1) : 0;

  // Function to format date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <>
      <div className="tab-pane fade" id="Reviews">
        <div className="comments-area">
          <div className="row">
            <div className="col-lg-8">
              <h4 className="mb-30">Customer Reviews</h4>
              <div className="comment-list">
                {reviews.map((review, index) => (
                  <div key={index} className="single-comment justify-content-between d-flex mb-30">
                    <div className="user justify-content-between d-flex">
                      <div className="thumb text-center">
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/blog/author-2.png`} alt="Author" /><br />
                        <a href="#" className="font-heading text-brand">{review.user_name}</a>
                      </div>
                      <div className="desc">
                        <div className="d-flex flex-column justify-content-between mb-10">
                          <div className="d-flex align-items-center">
                            <span className="font-xs text-muted">{formatDate(review.created_at)}</span>
                          </div>
                          <div className="product-rate d-inline-block">
                            <div className="product-rating" style={{ width: `${(review.rating * 20)}%` }} />
                          </div>
                        </div>
                        <p className="mb-10">
                          {review.comment ? review.comment : 'No comment'}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-lg-4">
              <h4 className="mb-30">Review preview</h4>
              <div className="d-flex mb-30">
                <div className="product-rate d-inline-block mr-15">
                  <div className="product-rating" style={{ width: `${(Number(averageRating) / 5) * 100 || 0}%` }} />
                </div>
                <h6>{(Number(averageRating) || 0).toFixed(1)} out of 5</h6>
              </div>

              {[5, 4, 3, 2, 1].map((star) => (
                <div className="progress" key={star}>
                  <span>{star} star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${totalReviews > 0 ? (ratingCounts[star - 1] / totalReviews) * 100 : 0}%`
                    }}
                    aria-valuenow={ratingCounts[star - 1] || 0}
                    aria-valuemin={0}
                    aria-valuemax={totalReviews || 1}
                  >
                    {totalReviews > 0
                      ? ((ratingCounts[star - 1] / totalReviews) * 100).toFixed(0)
                      : 0}%
                  </div>
                </div>
              ))}

              <a href="#" className="font-xs text-muted">Review ratings</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
